<template>
    <v-tooltip
        open-delay="400"
        :max-width="maxWidth"
        transition="scroll-x-transition"
        right>
        <template #activator="{ on }">
            <v-icon
                dense
                v-on="on">
                {{ icon }}
            </v-icon>
        </template>
        <span style="white-space: pre-wrap;">{{ helpText }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'CopilotInfoIcon',
        props: {
            icon: {
                type: String,
                default: 'mdi-help-circle-outline',
            },
            helpText: {
                type: String,
                required: true,
            },
            maxWidth: {
                type: Number,
                default: 400,
            },
        },
    }
</script>
