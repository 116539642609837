export function serviceNetworkTrafficReceived ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_bytes_total{kubernetes_namespace="${id}", mariadb=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceNetworkTrafficReceivedCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_bytes_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceNetworkTrafficSent ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_bytes_total{kubernetes_namespace="${id}", mariadb=~".+"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceNetworkTrafficSentCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_bytes_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceMariaDBNetworkTrafficReceived ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_bytes_received{kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceMariaDBNetworkTrafficSent ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_bytes_sent{kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceNetworkUsageReceived ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_global_status_bytes_received{kubernetes_namespace="${id}"}[1h]))`
    }
}

export function serviceNetworkUsageSent ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_global_status_bytes_sent{kubernetes_namespace="${id}"}[1h]))`
    }
}

export function serviceNetworkErrorsReceive ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_errors_total{kubernetes_namespace="${id}", mariadb=~".+"}[300s]))`
    }
}

export function serviceNetworkErrorsReceiveCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_errors_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceNetworkErrorsTransmit ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_errors_total{kubernetes_namespace="${id}", mariadb=~".+"}[300s]))`
    }
}

export function serviceNetworkErrorsTransmitCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_errors_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceNetworkReceivePacketsDropped ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_packets_dropped_total{kubernetes_namespace="${id}", mariadb=~".+"}[300s]))`
    }
}

export function serviceNetworkReceivePacketsDroppedCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_packets_dropped_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceNetworkTransmitPacketsDropped ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_packets_dropped_total{kubernetes_namespace="${id}", mariadb=~".+"}[300s]))`
    }
}

export function serviceNetworkTransmitPacketsDroppedCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_packets_dropped_total{kubernetes_pod_name=~"cs-node.*", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverNetworkTrafficReceived ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_bytes_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverNetworkTrafficSent ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_bytes_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverMariaDBNetworkTrafficReceived ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_bytes_received{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverMariaDBNetworkTrafficSent ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_bytes_sent{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverNetworkErrorsReceive ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_errors_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverNetworkErrorsTransmit ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_errors_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverNetworkReceivePacketsDropped ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_receive_packets_dropped_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverNetworkTransmitPacketsDropped ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(container_network_transmit_packets_dropped_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandIoNetworkLatencySecondsAgg ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_io_network_latency_seconds{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"} * 1000) by (agg)`
    }
}

export function xpandIoNetworkLatencySeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `mariadb_xpand_io_network_latency_seconds{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"} * 1000`
    }
}

export function xpandIoNetworkLatencySecondsMax ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(mariadb_xpand_io_network_latency_seconds{mariadb="${name}", kubernetes_namespace="${id}", agg="avg"} * 1000)`
    }
}

export function xpandIoNetworkBytesRX ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `rate(mariadb_xpand_io_network_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", if="eth0", io="rx"}[300s])`
    }
}

export function xpandIoNetworkBytesTX ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `rate(mariadb_xpand_io_network_bytes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", if="eth0", io="tx"}[300s])`
    }
}
