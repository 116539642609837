<template>
    <v-select
        :class="['std std--dropdown select-dropdown']"
        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
        :items="datasources"
        item-text="name"
        item-value="id"
        :value="selectedDatasource?.id"
        :loading="areDatasourcesLoading"
        :disabled="isSelectionDisabled"
        :error="datasourcesError"
        height="36"
        outlined
        attach
        single-line
        @change="changeDatasource">
        <template #selection="{ item }">
            <v-icon dense>
                {{ item.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
            </v-icon>
            <span class="pl-1">
                {{ item.name }}
            </span>
        </template>
        <template #item="{ item }">
            <v-icon dense>
                {{ item.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
            </v-icon>
            <span class="pl-1">
                {{ item.name }}
            </span>
        </template>
    </v-select>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotDatasourcesMenu',
        computed: {
            ...mapGetters([
                'datasources',
                'datasourcesError',
                'areDatasourcesLoading',
                'selectedDatasource',
                'selectedAgent'
            ]),
            isSelectionDisabled() {
                return this.selectedAgent?.type !== 'SYSTEM' || this.areDatasourcesLoading
            },
        },
        methods: {
            ...mapActions([
                'selectDatasource'
            ]),
            changeDatasource(datasourceId) {
                this.selectDatasource(datasourceId)
            },
        },
    }
</script>
