<style lang="scss" scoped>
    pre {
        white-space: pre-wrap;
    }
</style>

<template>
    <v-snackbar
        v-model="message.status"
        :color="message.type"
        :bottom="true"
        :right="true"
        :timeout="message.timeout * 1000">
        <div v-if="message.type !== 'error'">
            {{ message.text }}
        </div>
        <pre v-else>{{ message.text }}</pre>
        <template #action="{ attrs }">
            <v-btn
                class="mr-0"
                v-bind="attrs"
                text
                icon
                @click="message.status = false">
                <v-icon color="white">
                    mdi-close
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'Snackbars',
        computed: {
            message () {
                return this.$store.state.message
            },
        },
    }
</script>
