<style lang="scss" scoped>
    .announcement-banner {
        top: 64px;
        margin-left: 235px;
        border-radius: 0;
    }
</style>

<template>
    <div>
        <v-alert
            class="mb-0 announcement-banner text-center"
            text
            dense
            :color="color">
            <v-row align="center">
                <v-col class="grow">
                    {{ message }}
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>
<script>
    export default {
        name: 'AnnouncementBanner',
        props: {
            message: {
                type: String,
                default: '',
            },
            color: {
                type: String,
                default: 'info',
            },
        },
    }
</script>
