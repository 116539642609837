<style lang="scss" scoped>
    .service-container {
        width: 100%;
        max-width: none;
    }
</style>

<template>
    <InitializingMessage v-if="!servicesInitialized" />
    <v-container
        v-else
        class="service-container d-flex flex-column px-12 pt-12">
        <ServiceHeader
            class="d-flex flex-column"
            :service="getServiceById($route.params.id)" />
        <router-view />
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ServiceHeader from 'components/service/Header.vue'
    import InitializingMessage from 'components/messages/Initializing.vue'

    export default {
        name: 'ServiceView',
        components: {
            InitializingMessage,
            ServiceHeader,
        },
        computed: {
            ...mapGetters(['servicesInitialized', 'getServiceById']),
            service() {
                return this.getServiceById(this.$route.params.id)
            },
        },
        methods: {
            ...mapActions(['fetchServices']),
        },
        created() {
            this.fetchServices()
        },
    }
</script>
