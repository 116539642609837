<style lang="scss" scoped>
    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>

<template>
    <div class="menu">
        <v-tabs :value="selectedChatSession?.id">
            <v-tab
                v-for="(chatSession, i) in agentChatSessions"
                :key="chatSession.id"
                :tab-value="chatSession.id"
                :disabled="isChatSessionLoading"
                @change="selectChatSession(chatSession.id)">
                <span v-if="chatSession.summary">
                    <truncate-string
                        :text="chatSession.summary"
                        max-width="200px"
                        :dot-count="3"
                        :has-tooltip="true" />
                </span>
                <span v-else>
                    {{ $t('copilot.newChat') }} ({{ i + 1 }})
                </span>
                <span v-if="chatSession.agentConfig?.dbName">
                    (<span class="text-none info--text">
                        <v-icon small>mdi-table-multiple</v-icon>
                        {{ chatSession.agentConfig.dbName }}</span>)
                </span>
                <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            class="ml-1"
                            v-bind="attrs"
                            icon
                            small
                            v-on="on">
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="clearChatSession(chatSession.id)">
                            <v-list-item-title>
                                <v-icon>mdi-cancel</v-icon>
                                {{ $t('clear') }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmChatSessionRemoval(chatSession.id)">
                            <v-list-item-title class="error--text">
                                <v-icon color="error">
                                    mdi-delete
                                </v-icon>
                                {{ $t('delete') }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-tab>
            <v-btn
                class="mt-2"
                color="primary"
                icon
                @click="newChat">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-tabs>
        <ConfirmationModal
            ref="confirmChatSessionDeleteModal"
            message="confirmations.deleteChatSession"
            :on-confirm="() => removeChatSession(confirmedChatSessionId)" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'

    export default {
        name: 'CopilotChatsMenu',
        components: {
            ConfirmationModal,
        },
        data() {
            return {
                prompt: '',
                confirmedChatSessionId: null,
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent',
                'selectedDatasource',
                'selectedDatasourceSchema',
                'agentChatSessions',
                'selectedChatSession',
                'isChatSessionLoading'
            ]),
        },
        methods: {
            ...mapActions([
                'selectChatSession',
                'newChatSession',
                'clearChatSession',
                'removeChatSession'
            ]),
            async newChat() {
                this.newChatSession({
                    agentId: this.selectedAgent.id,
                    datasourceId: this.selectedDatasource.id,
                    datasourceSchema: this.selectedDatasourceSchema,
                })
            },
            confirmChatSessionRemoval(chatSessionId) {
                this.confirmedChatSessionId = chatSessionId
                this.$refs.confirmChatSessionDeleteModal.open()
            },
        },
    }
</script>
