<style lang="scss" scoped>
    .time-range-input {
        max-width: 242px;
        font-size: 14px;
        &.v-input ::v-deep .v-input__control {
            .v-input__prepend-inner {
                margin-top: 6px;
            }
            .v-input__slot {
                min-height: 36px;
            }
        }
    }

    .inner-icon {
        width: 16px;
    }
</style>

<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :return-value.sync="monthYear"
        :close-on-content-click="false"
        min-width="290px"
        offset-y
        internal-activator>
        <template #activator="{ on, attrs }">
            <v-text-field
                v-model="selectedMonthTxt"
                v-bind="attrs"
                class="pa-0 time-range-input"
                hide-details="auto"
                outlined
                dense
                readonly
                v-on="on">
                <template #prepend-inner>
                    <v-icon class="inner-icon">
                        $vuetify.icons.calendar
                    </v-icon>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            v-model="monthYear"
            color="primary"
            type="month"
            :min="min"
            :max="max || getLastMonth"
            no-title
            scrollable>
            <v-spacer />
            <v-btn
                text
                @click="menu = false">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                depressed
                rounded
                @click="$refs.menu.save(monthYear); onMonthYearChange()">
                {{ $t('ok') }}
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        name: 'MonthYearPicker',
        props: {
            min: {
                type: String,
                default: '2024-08',
            },
            max: {
                type: String,
                default: '',
            },
        },
        data () {
            return {
                selectedMonthTxt: '',
                monthYear: this.$moment().subtract(1,'months').format('YYYY-MM'),
                menu: false,
            }
        },
        computed: {
            getLastMonth() {
                return this.$moment().subtract(1,'months').format('YYYY-MM')
            },
        },
        methods: {
            onMonthYearChange () {
                this.selectedMonthTxt = this.$moment(this.monthYear).format('MMMM, YYYY')
                this.$emit('change', {
                    year: this.$moment(this.monthYear).year(),
                    month: this.$moment(this.monthYear).month() + 1,
                })
            },
        },
        created () {
            this.onMonthYearChange()
        },
    }
</script>
