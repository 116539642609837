<style lang="scss" scoped>
    .prompt-container {
        width: 100%;
        max-width: 768px;
        margin: 6px auto 0;
        box-shadow: 0px -12px 12px white;
        z-index: 1;
    }
    .prompt-alert .v-alert {
        margin-bottom: -4px;
    }
</style>
<style lang="scss">
    .prompt-alert .prompt-input.v-textarea {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .prompt-input.v-textarea {
        background-color: white;
        textarea {
            max-height: 112px;
            margin: 10px 10px 10px 0;
            overflow: auto;
            margin-top: 14px !important;
        }
        .v-input__append-inner {
            margin-top: 10px;
        }
    }
</style>

<template>
    <div :class="['prompt-container', isTokensUsageThresholdReached ? 'prompt-alert' : '']">
        <v-alert
            v-if="isTokensUsageThresholdReached"
            class="px-1 pt-0 pb-1 text-caption"
            outlined
            :type="isTokensLimitReached ? 'error' : 'warning'"
            text>
            <span>
                {{ isTokensLimitReached ? $t('copilot.reachedYour') : $t('copilot.reachedYourPercent', [$format('percentunit', totalTokensUsagePercent)]) }}
                <v-tooltip
                    open-delay="400"
                    right>
                    <template #activator="{ on }">
                        <span
                            class="text-decoration-underline text-lowercase"
                            v-on="on"> {{ $t('copilot.tokensQuota') }}</span>
                    </template>
                    <table>
                        <tr>
                            <td>{{ $t('input') }}:</td>
                            <td
                                v-if="inputTokensQuota"
                                class="pl-2 text-right">
                                {{ $format('short', inputTokensQuota?.usage) }}/{{ $format('short', inputTokensQuota?.value) }}
                            </td>
                            <td v-else>n/a</td>
                        </tr>
                        <tr>
                            <td>{{ $t('output') }}:</td>
                            <td
                                v-if="outputTokensQuota"
                                class="pl-2 text-right">
                                {{ $format('short', outputTokensQuota?.usage) }}/{{ $format('short', outputTokensQuota?.value) }}
                            </td>
                            <td v-else>n/a</td>
                        </tr>
                    </table>
                </v-tooltip><span class="text-lowercase">{{ isTokensLimitReached ? ` ${$t('limit')}` : '' }}.</span>
            </span>
            {{ $t('copilot.tokensToIncrease') }} <a
                class="text-lowercase"
                :href="$config.CSM_URL"
                target="_blank">
                {{ $t('contact-us') }}</a>.
        </v-alert>
        <v-textarea
            v-model="prompt"
            class="prompt-input"
            :loading="isChatSessionLoading"
            label=""
            rows="1"
            :placeholder="$t('copilot.promptPlaceholder')"
            auto-grow
            autofocus
            outlined
            hide-details
            @keydown.enter.exact.prevent="sendPrompt(prompt)">
            <template #append>
                <v-btn
                    color="primary"
                    :disabled="isChatSessionLoading || isPromptEmpty"
                    icon
                    @click="sendPrompt(prompt)">
                    <v-icon>mdi-send</v-icon>
                </v-btn>
            </template>
        </v-textarea>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'CopilotChatPromptInput',
        data() {
            return {
                prompt: '',
                tokenUsagePercentThreshold: 0.8,
            }
        },
        computed: {
            ...mapGetters([
                'isChatSessionLoading',
                'inputTokensQuota',
                'outputTokensQuota'
            ]),
            isPromptEmpty() {
                return /^\s*$/.test(this.prompt)
            },
            totalTokensUsagePercent() {
                return Math.max(0, this.inputTokensQuota?.usagePercent, this.outputTokensQuota?.usagePercent)
            },
            isTokensUsageThresholdReached() {
                return this.totalTokensUsagePercent >= this.tokenUsagePercentThreshold
            },
            isTokensLimitReached() {
                return this.totalTokensUsagePercent >= 1
            },
        },
        methods: {
            sendPrompt () {
                if (this.isPromptEmpty) {
                    return false
                } else {
                    this.$emit('input', this.prompt)
                    this.prompt = ''
                }
            },
        },
    }
</script>
