<template>
    <base-dialog
        v-model="isDialogOpen"
        :should-dismiss-on-save="false"
        :should-show-overlay="isEditing"
        :on-save="saveAgent"
        :on-cancel="close"
        :min-width="776"
        :max-width="776"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6">
                {{ isEditing ? $t('copilot.editAgent') : $t('copilot.createAgent') }}
            </div>
        </template>
        <template #body>
            <div v-if="isGuideVisible">
                <v-alert
                    outlined
                    type="info"
                    text>
                    {{ $t('info.copilotAgentCreationGuide') }}
                </v-alert>
                <iframe
                    width="680"
                    height="382"
                    :src="$config.COPILOT_VIDEO_EMBED"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen></iframe>
            </div>
            <div v-else-if="isSaving">
                <v-alert
                    outlined
                    type="info"
                    text>
                    {{ $t('info.copilotGeneratingAgentContext') }}
                </v-alert>
                <v-progress-linear
                    indeterminate
                    color="primary" />
            </div>
            <div v-else-if="isSaved">
                <v-alert
                    outlined
                    type="success"
                    text>
                    {{ $t('info.copilotGeneratedAgentContext') }}
                </v-alert>
            </div>
            <div v-else>
                <v-row class="my-6">
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('name') }}</label>
                        <v-text-field
                            v-model="name"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('copilot.agentName')"
                            maxlength="24"
                            dense
                            hide-details
                            autofocus />
                    </v-col>
                </v-row>
                <v-row class="my-6">
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $tc('copilot.datasource', 1) }}</label>
                        <v-select
                            v-model="datasourceId"
                            :class="['std std--dropdown select-dropdown']"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            :items="datasources"
                            :disabled="isEditing"
                            :loading="areDatasourcesLoading"
                            item-text="name"
                            item-value="id"
                            height="36"
                            outlined
                            attach
                            single-line
                            @change="updateDatasourceSchema">
                            <template #selection="{ item }">
                                <v-icon dense>
                                    {{ item.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
                                </v-icon>
                                <span class="pl-1">
                                    {{ item.name }}
                                </span>
                            </template>
                            <template #item="{ item }">
                                <v-icon dense>
                                    {{ item.type === 'SYSTEM' ? 'mdi-database-eye' : 'mdi-database' }}
                                </v-icon>
                                <span class="pl-1">
                                    {{ item.name }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('copilot.schema') }}</label>
                        <v-autocomplete
                            v-model="datasourceSchema"
                            :class="['std std--dropdown select-dropdown']"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            :items="datasourceSchemas"
                            :disabled="isEditing"
                            :loading="areDatasourcesLoading || isAgentContextLoading"
                            height="36"
                            outlined
                            attach
                            single-line>
                            <template #selection="{ item }">
                                <v-icon dense>
                                    mdi-table-multiple
                                </v-icon>
                                <span class="pl-1">
                                    {{ item }}
                                </span>
                            </template>
                            <template #item="{ item }">
                                <v-icon dense>
                                    mdi-table-multiple
                                </v-icon>
                                <span class="pl-1">
                                    {{ item }}
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="my-6">
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">
                            {{ $t('description') }}
                            <CopilotInfoIcon
                                :help-text="$t('copilot.agentDescription')"
                                :max-width="560" />
                        </label>
                        <v-textarea
                            v-model="description"
                            type="text"
                            rows="8"
                            required
                            outlined
                            :placeholder="$t('copilot.agentDescription')"
                            dense
                            hide-details />
                    </v-col>
                </v-row>
                <v-row
                    v-if="isEditing"
                    class="my-6">
                    <v-col class="pt-0 pb-0">
                        <label class="text-uppercase">
                            {{ $t('status') }}
                        </label>
                        <v-radio-group
                            v-model="status"
                            class="mt-0"
                            hide-details
                            row>
                            <v-radio
                                :label="$t('copilot.agentStatus.DEVELOPMENT')"
                                value="DEVELOPMENT" />
                            <v-radio
                                :label="$t('copilot.agentStatus.DEPLOYED')"
                                value="DEPLOYED" />
                        </v-radio-group>
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-switch
                v-if="isGuideVisible"
                v-model="markGuideAsRead"
                :label="$t('dontShowAgain')"
                color="primary"
                class="ma-0 pa-0"
                hide-details
                small />
            <v-spacer />

            <div v-if="isGuideVisible">
                <v-btn
                    color="primary"
                    class="save"
                    style="min-width: 100px;"
                    depressed
                    rounded
                    @click="acknowledge">
                    <span class="text-none"> {{ $t('ok') }}</span>
                </v-btn>
            </div>
            <div v-else-if="isSaved">
                <v-btn
                    color="primary"
                    class="save"
                    style="min-width: 100px;"
                    depressed
                    rounded
                    @click="finish">
                    <span class="text-none"> {{ $t('ok') }}</span>
                </v-btn>
            </div>
            <div v-else>
                <v-btn
                    v-if="!isSaving"
                    text
                    rounded
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('cancel') }} </span>
                </v-btn>
                <v-btn
                    color="primary"
                    class="save"
                    style="min-width: 100px;"
                    :disabled="!isFormValid"
                    :loading="isSaving"
                    depressed
                    rounded
                    @click="save">
                    <span class="text-none"> {{ $t('save') }}</span>
                </v-btn>
            </div>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { createContext, generateAgentContext } from 'services/copilot.js'
    import CopilotInfoIcon from 'components/copilot/InfoIcon.vue'

    export default {
        name: 'CopilotAgentModal',
        components: {
            CopilotInfoIcon,
        },
        data() {
            return {
                isGuideVisible: true,
                markGuideAsRead: false,
                isDialogOpen: false,
                isSaving: false,
                isSaved: false,
                id: null,
                name: '',
                description: '',
                datasourceId: null,
                datasourceSchema: null,
                status: null,
                newAgentId: null,
            }
        },
        computed: {
            ...mapGetters([
                'agents',
                'datasources',
                'selectedDatasource',
                'areDatasourcesLoading',
                'isAgentContextLoading'
            ]),
            isEditing() {
                return Boolean(this.id)
            },
            datasourceSchemas() {
                const datasource = this.datasources.find(datasource => datasource.id === this.datasourceId)
                if (!datasource) {
                    return []
                } else {
                    return datasource.schemas.map(datasource => datasource.name)
                }
            },
            isFormValid() {
                return !!this.name &&
                    !!this.description &&
                    !!this.datasourceId &&
                    !!this.datasourceSchema
            },
        },
        methods: {
            ...mapActions([
                'newAgent',
                'editAgent',
                'refreshAgents',
                'selectAgent',
                'fetchAgentContext',
                'selectDatasource',
                'fetchDatasource'
            ]),
            open(agentId) {
                this.id = agentId ? agentId : null
                if (agentId) {
                    const agent = this.agents.find(agent => agent.id === agentId)
                    this.name = agent.name
                    this.description = agent.description
                    this.datasourceId = agent.datasourceId
                    this.status = agent.status
                    this.datasourceSchema = null
                    this.updateDatasourceSchema(agent.datasourceId)
                } else {
                    this.name = ''
                    this.description = ''
                    this.datasourceId = this.selectedDatasource.id
                    this.datasourceSchema = this.selectedDatasource?.schemas[0]?.name
                    this.status = null
                }
                this.isGuideVisible = !agentId && localStorage.getItem('copilot-create-agent-guide-read') !== 'yes'
                this.newAgentId = null
                this.isSaving = false
                this.isSaved = false
                this.isDialogOpen = true
            },
            close() {
                this.isDialogOpen = false
            },
            acknowledge() {
                this.isGuideVisible = false
                if (this.markGuideAsRead) {
                    localStorage.setItem('copilot-create-agent-guide-read', 'yes')
                } else {
                    localStorage.removeItem('copilot-create-agent-guide-read')
                }
            },
            async updateDatasourceSchema(datasourceId) {
                const selectedDatasource = this.datasources.find(datasource => datasource.id === datasourceId)
                if (selectedDatasource) {
                    if (!selectedDatasource.schemas.length) {
                        await this.fetchDatasource(selectedDatasource.id)
                    }
                    if (this.isEditing) {
                        const agent = this.agents.find(agent => agent.id === this.id)
                        if (agent) {
                            if (!agent.context) {
                                await this.fetchAgentContext(agent.id)
                            }
                            this.datasourceSchema = agent.context?.name
                        }
                    } else {
                        this.datasourceSchema = selectedDatasource.schemas[0]?.name
                    }
                }
            },
            async createAgent() {
                this.isSaving = true
                const [error, data] = await to(createContext(this.datasourceId, this.datasourceSchema))
                if (error) {
                    this.$store.commit('showMessage', { text: this.$t('errors.createContext'), type: this.$config.messageType.ERROR, })
                } else {
                    const newAgent = await this.newAgent({
                        name: this.name,
                        description: this.description,
                        contextId: data.id,
                    })
                    const [error] = await to(generateAgentContext(newAgent.id))
                    if (error) {
                        this.$store.commit('showMessage', { text: this.$t('errors.createContext'), type: this.$config.messageType.ERROR, })
                    } else {
                        this.newAgentId = newAgent.id
                        this.isSaved = true
                    }
                }
                this.isSaving = false
            },
            async finish() {
                if (this.datasourceId !== this.selectedDatasource?.id) {
                    await this.selectDatasource(this.datasourceId)
                }
                this.selectAgent(this.newAgentId)
                this.close()
            },
            async saveAgent() {
                if (this.isEditing) {
                    const savedId = await this.editAgent({
                        agentId: this.id,
                        data: {
                            name: this.name,
                            description: this.description,
                            status: this.status,
                        },
                    })
                    if (savedId) {
                        this.close()
                    }
                    this.refreshAgents()
                } else {
                    await this.createAgent()
                }
            },
        },
    }
</script>
