import alerts from 'config/alerts'
import launchService from 'config/launchService'
import i18n from 'plugins/i18n'
import common from 'config/common'
import MONITORING from 'config/monitoring'
import CATEGORIES from 'config/categories'
import BILLING from 'config/billing'
import CONFIG_MANAGER from 'config/configManager'
import PARTNER_INTEGRATIONS from 'config/partnerIntegrations'
import BACKUPS from 'config/backups.js'
import COPILOT from 'config/copilot.js'

const DOCS_URL = 'https://skysqlinc.github.io/skysql-docs/'

export const CONFIG = Object.freeze({
    DOCS_URL,
    productName: i18n.t('productName', ['SkySQL']),
    navigation: [
        {
            title: i18n.t('dashboard'),
            route: 'dashboard',
            size: 22,
            icon: '$vuetify.icons.dashboard',
        },
        {
            title: i18n.t('alerts'),
            route: 'alerts',
            size: 22,
            icon: '$vuetify.icons.alerts',
        },
        {
            title: i18n.tc('log', 2),
            route: 'logs',
            size: 25,
            icon: '$vuetify.icons.logs',
        },
        {
            title: i18n.t('aiCopilot'),
            url: common.AI_COPILOT_URL,
            size: 26,
            icon: '$vuetify.icons.aiCopilot',
            showFlag: 'enable-portal-ai-copilot',
            isTechPreview: true,
        },
        {
            title: i18n.t('copilot.navItem'),
            route: 'copilot',
            size: 26,
            icon: '$vuetify.icons.aiCopilot',
            showFlag: 'enable-portal-copilot-v2',
            isTechPreview: true,
        },
        {
            title: i18n.tc('backups.backup', 2),
            route: 'backups',
            size: 22,
            icon: '$vuetify.icons.cloudBackupIcon',
            showFlag: 'enable-portal-cloud-backup',
            isTechPreview: true,
        },
        {
            title: i18n.t('settings'),
            route: 'settings',
            size: 22,
            icon: '$vuetify.icons.settings',
        },
        {
            title: i18n.t('integrations'),
            route: 'integrations',
            size: 22,
            icon: '$vuetify.icons.integrations',
        },
        {
            title: i18n.t('apiAccess'),
            isMenu: true,
            size: 30,
            icon: '$vuetify.icons.apiIcon',
            menuItems: [
                { title: i18n.t('apiDoc'), url: process.env.VUE_APP_SWAGGER_URL, },
                { title: i18n.t('backupServiceDocs'), url: process.env.VUE_APP_BACKUP_SERVICE_DOCS_URL, },
                { title: i18n.t('manageKeys'), route: 'userProfile', params: { tab: 'api-keys', }, }
            ],
        },
        {
            title: i18n.t('support'),
            size: 22,
            icon: '$vuetify.icons.comments',
            url: common.SUPPORT_URL,
            isBottomNav: true,
        },
        {
            title: i18n.t('documentation'),
            size: 22,
            icon: '$vuetify.icons.questionCircle',
            url: DOCS_URL,
        }
    ],
    keepAliveInterval: 30, // seconds
    fetchServicesInterval: 45, // seconds
    fetchUsageInterval: 15 * 50, // seconds
    fetchAlertsInterval: 30 * 60, // seconds
    logsFetchInterval: 30, // seconds
    backupsFetchInterval: 30, // seconds
    maxLoginAttempts: 5,
    ...alerts,
    ...launchService,
    ...common,
    ...MONITORING,
    ...CATEGORIES,
    ...BILLING,
    ...CONFIG_MANAGER,
    ...{PARTNER_INTEGRATIONS,},
    ...BACKUPS,
    ...COPILOT,
})
