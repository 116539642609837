<template>
    <v-container
        fluid
        class="pt-7">
        <v-row>
            <v-col cols="6">
                <TaxCard
                    :loading="isFetchingTax"
                    :tax="tax || {}" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import TaxCard from 'components/billing/taxManagement/TaxCard.vue'

    export default {
        name: 'TaxManagement',
        components: { TaxCard, },
        computed: {
            ...mapGetters([
                'tax',
                'isFetchingTax'
            ]),
        },
        methods: {
            ...mapActions(['fetchTax']),
        },
        async created() {
            await this.fetchTax()
        },
    }
</script>
