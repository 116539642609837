<style lang="scss" scoped>
    .cls-1,.cls-2{fill:#d9dae0;}.cls-2,.cls-3,.cls-5,.cls-6,.cls-7{stroke:#2d5765;}.cls-10,.cls-11,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8{stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}.cls-3,.cls-4,.cls-9{fill:#fff;}.cls-4,.cls-8{stroke:#345e6b;}.cls-5,.cls-8{fill:#2f99a3;}.cls-11,.cls-6{fill:none;}.cls-7{fill:#29787c;opacity:0.5;}.cls-10{fill:#2d5765;}.cls-10,.cls-11{stroke:#fff;}
</style>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
            class="cls-1"
            d="M454.31255,386.29829l-144.197,85.99652a4.1529,4.1529,0,0,1-4.424-.10684L254.94461,438.5a4.15288,4.15288,0,0,1,.1697-7.02666l144.197-85.99652a4.15292,4.15292,0,0,1,4.424.10684l50.74694,33.688A4.15289,4.15289,0,0,1,454.31255,386.29829Z" />
        <path
            class="cls-1"
            d="M175.27765,449.09874,50.65188,372.89259a4.94591,4.94591,0,0,1,0-8.43914l124.62577-76.20614a9.85478,9.85478,0,0,1,10.28208,0L310.1855,364.45345a4.94591,4.94591,0,0,1,0,8.43914L185.55973,449.09874A9.85482,9.85482,0,0,1,175.27765,449.09874Z" />
        <path
            class="cls-2"
            d="M175.49032,395.22922,55.32479,321.75043A13.41556,13.41556,0,0,1,48.90786,310.283l.06548-39.87378,126.517-30.00038a9.48525,9.48525,0,0,1,9.89655,0l126.9082,28.34731-.26567,41.59768a13.41559,13.41559,0,0,1-6.41668,11.35971L185.38687,395.22922A9.48525,9.48525,0,0,1,175.49032,395.22922Z" />
        <path
            class="cls-3"
            d="M175.27765,348.21244,50.65188,272.0063a4.94591,4.94591,0,0,1,0-8.43914L175.27765,187.361a9.85482,9.85482,0,0,1,10.28208,0L310.1855,263.56716a4.94591,4.94591,0,0,1,0,8.43914L185.55973,348.21244A9.85478,9.85478,0,0,1,175.27765,348.21244Z" />
        <line
            class="cls-3"
            x1="180.41869"
            y1="349.6597"
            x2="180.41869"
            y2="387.78832" />
        <path
            class="cls-2"
            d="M175.49032,314.52019,55.32479,241.04139A13.41555,13.41555,0,0,1,48.90786,229.574l.06548-39.87378,126.517-30.00039a9.4853,9.4853,0,0,1,9.89655,0l126.9082,28.34732-.26567,41.59768a13.41556,13.41556,0,0,1-6.41668,11.3597L185.38687,314.52019A9.4853,9.4853,0,0,1,175.49032,314.52019Z" />
        <path
            class="cls-3"
            d="M175.27765,267.50341,50.65188,191.29727a4.94592,4.94592,0,0,1,0-8.43915L175.27765,106.652a9.85478,9.85478,0,0,1,10.28208,0L310.1855,182.85812a4.94592,4.94592,0,0,1,0,8.43915L185.55973,267.50341A9.85478,9.85478,0,0,1,175.27765,267.50341Z" />
        <line
            class="cls-3"
            x1="180.41869"
            y1="268.95067"
            x2="180.41869"
            y2="307.07929" />
        <path
            class="cls-2"
            d="M175.49032,225.31547,55.32479,151.83667a13.41556,13.41556,0,0,1-6.41693-11.46742l.06548-39.87378,126.517-30.00038a9.4853,9.4853,0,0,1,9.89655,0L312.29507,98.8424l-.26567,41.59769a13.41556,13.41556,0,0,1-6.41668,11.3597L185.38687,225.31547A9.4853,9.4853,0,0,1,175.49032,225.31547Z" />
        <path
            class="cls-4"
            d="M175.27765,178.29869,50.65188,102.09254a4.94591,4.94591,0,0,1,0-8.43914L175.27765,17.44726a9.85478,9.85478,0,0,1,10.28208,0L310.1855,93.6534a4.94591,4.94591,0,0,1,0,8.43914L185.55973,178.29869A9.85482,9.85482,0,0,1,175.27765,178.29869Z" />
        <line
            class="cls-3"
            x1="180.41869"
            y1="179.74594"
            x2="180.41869"
            y2="217.87456" />
        <path
            class="cls-1"
            d="M425.78268,247.46612c-.69445,16.21074-30.12232,11.79942-45.08608,10.98891s-26.53133-14.609-25.83688-30.81969,12.09774-28.765,27.0615-27.95446S426.47713,231.25539,425.78268,247.46612Z" />
        <path
            class="cls-2"
            d="M428.45434,230.64846,391.06857,203.7677a16.05707,16.05707,0,0,0-8.1034-3.14531c-5.924-.5354-12.17271,1.10233-18.09,4.5309" />
        <path
            class="cls-2"
            d="M386.09481,219.24311l-28.91916-19.65336a28.51541,28.51541,0,0,0-19.72881-4.71592c-24.67719,3.29647-70.29625,38.41605-68.22365,117.10915-14.65342,12.97108-26.23775,37.47046-27.17238,59.28777v.00008c-.57294,13.37439,2.96987,23.05776,9.08267,27.67824l.0024-.00309,36.35444,27.06735" />
        <path
            class="cls-4"
            d="M437.61409,260.2716c1.09336-27.7873-13.94088-41.04763-33.668-29.61738a47.72164,47.72164,0,0,0-6.23852,4.36792c-6.87737-18.85625-23.07928-25.26-42.65305-13.91857C327.94672,236.81036,304.6411,280.59828,303,318.90667a80.42193,80.42193,0,0,0,1.31017,19.28047c-14.65343,12.97107-26.23776,37.47046-27.17239,59.28776v.00009c-1.08312,25.28348,12.5425,37.37612,30.43365,27.00964L429.29756,353.9542c17.89115-10.36647,33.27285-39.2665,34.356-64.55v-.00008C464.646,266.23719,453.286,254.159,437.61409,260.2716Z" />
        <line
            class="cls-2"
            x1="269.74995"
            y1="312.56525"
            x2="292.63765"
            y2="329.92833" />
        <line
            class="cls-5"
            x1="275.64628"
            y1="237.53773"
            x2="258.6549"
            y2="248.15734" />
        <path
            class="cls-6"
            d="M369.09884,389.39815l50.69806,30.61014a3.89375,3.89375,0,0,1-.03459,6.68724L303.16136,495.46019a3.89377,3.89377,0,0,1-4.02924-.04432L102.46248,373.52166a3.89375,3.89375,0,0,1-.064-6.57869l12.89171-8.34169" />
        <path
            class="cls-7"
            d="M178.11808,141.89566,112.61735,101.8433a7.31267,7.31267,0,0,1-3.49779-6.25075l.0357-21.7347L178.11808,57.505a5.17031,5.17031,0,0,1,5.39448,0l69.17607,15.45177-.14482,22.67437a7.31265,7.31265,0,0,1-3.49765,6.192l-65.5336,40.07247A5.17027,5.17027,0,0,1,178.11808,141.89566Z" />
        <path
            class="cls-6"
            d="M178.11808,141.89566,112.61735,101.8433a7.31267,7.31267,0,0,1-3.49779-6.25075l.0357-21.7347L178.11808,57.505a5.17031,5.17031,0,0,1,5.39448,0l69.17607,15.45177-.14482,22.67437a7.31265,7.31265,0,0,1-3.49765,6.192l-65.5336,40.07247A5.17027,5.17027,0,0,1,178.11808,141.89566Z" />
        <path
            class="cls-8"
            d="M178.00215,116.26741l-67.93194-41.539a2.696,2.696,0,0,1,0-4.60007l67.93194-41.539a5.37173,5.37173,0,0,1,5.60463,0l67.932,41.539a2.696,2.696,0,0,1,0,4.60007l-67.93195,41.539A5.37173,5.37173,0,0,1,178.00215,116.26741Z" />
        <line
            class="cls-3"
            x1="180.80447"
            y1="117.05629"
            x2="180.80447"
            y2="137.83972" />
        <path
            class="cls-9"
            d="M208.04636,70.365c3.54107-3.983,2.81559-9.44116-1.63413-12.21131a9.98738,9.98738,0,0,0-1.49857-.7613,10.50279,10.50279,0,0,0-4.93387-11.87463c-6.11455-3.80659-15.04572-2.46589-19.94832,2.99453a12.6995,12.6995,0,0,0-2.13579,3.23673,10.86012,10.86012,0,0,0-11.41207,2.81758h0c-3.23571,3.60388-2.58727,8.56206,1.44833,11.07441l27.457,17.09326a10.73608,10.73608,0,0,0,13.16588-1.97639h0C211.5197,77.45573,211.22128,73.01788,208.04636,70.365Z" />
        <line
            class="cls-10"
            x1="186.77646"
            y1="66.86701"
            x2="162.09568"
            y2="83.72978" />
        <polyline
            class="cls-11"
            points="181.097 96.081 188.918 90.963 150 67.49 143.094 71.379" />
        <ellipse
            class="cls-9"
            cx="136.18082"
            cy="75.97393"
            rx="4.90658"
            ry="3.31137"
            transform="translate(-14.10202 34.35278) rotate(-13.67653)" />
        <ellipse
            class="cls-9"
            cx="156.13249"
            cy="88.32496"
            rx="4.90658"
            ry="3.31137"
            transform="translate(-16.4566 39.42035) rotate(-13.67653)" />
        <ellipse
            class="cls-9"
            cx="174.184"
            cy="100.67599"
            rx="4.90658"
            ry="3.31137"
            transform="translate(-18.86504 44.03865) rotate(-13.67653)" />
        <path
            class="cls-5"
            d="M63.55989,235.9821l-3.17292-1.837a3.83138,3.83138,0,0,1-1.91171-3.31577V210.71282a1.565,1.565,0,0,1,2.34909-1.35437l3.17292,1.837a3.83136,3.83136,0,0,1,1.91171,3.31576v20.11656A1.565,1.565,0,0,1,63.55989,235.9821Z" />
        <path
            class="cls-5"
            d="M78.42734,245.53975l-3.17292-1.837a3.83135,3.83135,0,0,1-1.91171-3.31576V220.27047a1.565,1.565,0,0,1,2.3491-1.35438l3.17292,1.837a3.83136,3.83136,0,0,1,1.91171,3.31576v20.11656A1.565,1.565,0,0,1,78.42734,245.53975Z" />
        <path
            class="cls-5"
            d="M93.2948,255.0974l-3.17292-1.837a3.83135,3.83135,0,0,1-1.91171-3.31576V229.82812a1.565,1.565,0,0,1,2.34909-1.35438l3.17292,1.837a3.83135,3.83135,0,0,1,1.91171,3.31576V253.743A1.565,1.565,0,0,1,93.2948,255.0974Z" />
        <path
            class="cls-5"
            d="M64.09087,146.77738l-3.17292-1.837a3.83135,3.83135,0,0,1-1.91171-3.31576V121.5081a1.565,1.565,0,0,1,2.34909-1.35438l3.17292,1.837A3.83136,3.83136,0,0,1,66.44,125.30644V145.423A1.565,1.565,0,0,1,64.09087,146.77738Z" />
        <path
            class="cls-5"
            d="M78.95832,156.335l-3.17292-1.837a3.83135,3.83135,0,0,1-1.91171-3.31576V131.06575a1.565,1.565,0,0,1,2.3491-1.35438l3.17292,1.837a3.83135,3.83135,0,0,1,1.91171,3.31576v20.11656A1.565,1.565,0,0,1,78.95832,156.335Z" />
        <path
            class="cls-5"
            d="M93.82578,165.89268l-3.17292-1.837A3.83136,3.83136,0,0,1,88.74115,160.74V140.6234a1.565,1.565,0,0,1,2.34909-1.35438l3.17292,1.837a3.83138,3.83138,0,0,1,1.91171,3.31577V164.5383A1.565,1.565,0,0,1,93.82578,165.89268Z" />
        <path
            class="cls-5"
            d="M64.09087,316.69113l-3.17292-1.837a3.83136,3.83136,0,0,1-1.91171-3.31576V291.42186a1.565,1.565,0,0,1,2.34909-1.35438l3.17292,1.837A3.83138,3.83138,0,0,1,66.44,295.2202v20.11656A1.565,1.565,0,0,1,64.09087,316.69113Z" />
        <path
            class="cls-5"
            d="M78.95832,326.24878l-3.17292-1.837a3.83137,3.83137,0,0,1-1.91171-3.31576V300.9795a1.565,1.565,0,0,1,2.3491-1.35437l3.17292,1.837a3.83138,3.83138,0,0,1,1.91171,3.31577v20.11656A1.565,1.565,0,0,1,78.95832,326.24878Z" />
        <path
            class="cls-5"
            d="M93.82578,335.80643l-3.17292-1.837a3.83138,3.83138,0,0,1-1.91171-3.31577V310.53715a1.565,1.565,0,0,1,2.34909-1.35437l3.17292,1.837a3.83138,3.83138,0,0,1,1.91171,3.31577v20.11656A1.565,1.565,0,0,1,93.82578,335.80643Z" />
        <line
            class="cls-5"
            x1="295.82354"
            y1="133.46555"
            x2="258.6549"
            y2="156.82869" />
        <ellipse
            class="cls-5"
            cx="244.16751"
            cy="166.49849"
            rx="8.02397"
            ry="6.23267"
            transform="translate(-20.84025 297.30524) rotate(-60.56094)" />
        <ellipse
            class="cls-5"
            cx="244.16751"
            cy="255.70322"
            rx="8.02397"
            ry="6.23267"
            transform="translate(-98.52677 342.66606) rotate(-60.56094)" />
    </svg>
</template>
<script>
    export default {}
</script>
