
export default {
    state: {
        serviceId: null,
        isStartStopOpen: false,
        isScaleNodesOpen: false,
        isScaleStorageOpen: false,
        isScaleNodesUpOpen: false,
        isAutonomousOpen: false,
        isFetchingDependencies: false,
        isPrivateConnectOpen: false,
        isScalingOptionsOpen: false,
    },
    mutations: {
        setStartStopDialog (state, value) {
            state.isStartStopOpen = value
        },
        setScaleNodesDialog (state, value) {
            state.isScaleNodesOpen = value
        },
        setScaleStorageDialog (state, value) {
            state.isScaleStorageOpen = value
        },
        setManageServiceId (state, value) {
            state.serviceId = value
        },
        setScaleNodeUpDialog (state, value) {
            state.isScaleNodesUpOpen = value
        },
        setAutonomousDialog (state, value) {
            state.isAutonomousOpen = value
        },
        setServiceDepsFetching(state, value){
            state.isFetchingDependencies = value
        },
        setPrivateConnectDialog (state, value) {
            state.isPrivateConnectOpen = value
        },
        setScalingOptionsDialog (state, value) {
            state.isScalingOptionsOpen = value
        },
    },
    actions: {
        openStartStopDialog ({ commit, }, { popupState, serviceId,}  ) {
            commit('setStartStopDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
        openScaleNodesDialog ({ commit, }, { popupState, serviceId,}  ) {
            commit('setScaleNodesDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
        openScaleStorageDialog ({ commit, }, { popupState, serviceId,}  ) {
            commit('setScaleStorageDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
        openScaleNodeUpDialog ({ commit, }, { popupState, serviceId,}  ) {
            commit('setScaleNodeUpDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
        openAutonomousDialog ({ commit, }, { popupState, serviceId,}  ) {
            commit('setAutonomousDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
        setServiceDepsFetching({ commit, }, isFetching){
            commit('setServiceDepsFetching', isFetching)
        },
        openPrivateConnectModal({ commit, }, { popupState, serviceId,}  ) {
            commit('setPrivateConnectDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
        openScalingOptionstModal({ commit, }, { popupState, serviceId,}  ) {
            commit('setScalingOptionsDialog', popupState)
            commit('setManageServiceId', serviceId)
        },
    },

    getters: {
        isStartStopOpen: (state) => state.isStartStopOpen,
        isScaleNodesOpen: (state) => state.isScaleNodesOpen,
        isScaleStorageOpen: (state) => state.isScaleStorageOpen,
        serviceId: (state) => state.serviceId,
        isScaleNodesUpOpen: (state) => state.isScaleNodesUpOpen,
        isAutonomousOpen: (state) => state.isAutonomousOpen,
        isFetchingDependencies: (state) => state.isFetchingDependencies,
        isPrivateConnectOpen: (state) => state.isPrivateConnectOpen,
        isScalingOptionsOpen: (state) => state.isScalingOptionsOpen,
    },
}
