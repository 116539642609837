<template>
    <div>
        <base-dialog
            v-model="isScalingOptionsModalOpen"
            :min-width="900"
            :max-width="900"
            :on-save="updatePrivateConnect">
            <template #title>
                {{ $t('changeScalingOptions') }}
            </template>
            <template #body>
                <div class="ml-3 mt-8">
                    <div class="my-2 text-body-1 font-weight-bold">
                        {{ $t('launchService.scuScaling') }}
                    </div>
                    <v-range-slider
                        v-model="cpuScale"
                        :min="0"
                        :max="10"
                        hide-details>
                        <template #prepend>
                            <div
                                class="pt-1 text-body-2"
                                style="white-space: nowrap;">
                                <span class="font-weight-bold">Min:</span> {{ cpuScale[0] }} SCUs
                            </div>
                        </template>
                        <template #append>
                            <div
                                class="text-body-2"
                                style="white-space: nowrap;">
                                <span class="font-weight-bold">Max:</span> {{ cpuScale[1] }} SCUs
                            </div>
                        </template>
                    </v-range-slider>
                    <div class="mt-2 text-caption">
                        {{ $t('launchService.scuScaleInfo') }}
                    </div>
                </div>
            </template>
            <template #actions="{ cancel, save }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('cancel') }} </span>
                </v-btn>
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    @click="save">
                    <span class="text-none"> {{ $t('applyChanges') }}</span>
                </v-btn>
            </template>
        </base-dialog>
    </div>
</template>
<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'
    export default {
        name: 'ScaliningOptionsModal',
        components: { },
        data() {
            return {
                cpuScale: [2, 4],
            }
        },
        computed: {
            ...mapState(['manageServices']),
            ...mapGetters(['getServiceById']),
            service() {
                return this.getServiceById(this.manageServices.serviceId)
            },
            title() {
                return 'test'
            },
            isScalingOptionsModalOpen: {
                get() {
                    return this.manageServices.isScalingOptionsOpen
                },
                set(value) {
                    this.setScalingOptionsDialog(value)
                },
            },
        },
        methods: {
            ...mapMutations(['setScalingOptionsDialog']),
            async updatePrivateConnect() {
            },
        },
    }
</script>
