<style lang="scss" scoped>
.nav-title,
.nav-icon {
    color: $navigation;
}
.nav-title {
    line-height: 17px;
}
.nav-item {
    width: 100%;
}
.active {
    .nav-item > div {
        background-color: #E8EEF1;
        border-radius: 8px;
    }
    .nav-title,
    .nav-icon {
        color: $azure;
    }
}
</style>
<template>
    <v-list-item
        v-if="shouldShowItem"
        :class="{
            'navitem d-flex align-center my-2': true,
            active: matchedRoutes.indexOf(item.route) !== -1,
        }"
        @click="handleOnClick(item)">
        <v-menu
            v-if="item.isMenu"
            offset-x>
            <template #activator="{ on, attrs }">
                <div
                    class="nav-item d-flex align-center pl-2"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon
                        :size="item.size"
                        class="nav-icon mr-3">
                        {{ item.icon }}
                    </v-icon>
                    <span class="nav-title flex-grow-1">{{ item.title }}</span>
                    <v-icon size="12">
                        $vuetify.icons.arrowRight
                    </v-icon>
                </div>
            </template>
            <v-list>
                <v-list-item
                    v-for="(menuItem, index) in item.menuItems"
                    :key="index"
                    class="custom-click"
                    @click="handleOnClick(menuItem)">
                    <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div
            v-else
            class="nav-item custom-click">
            <div class="d-inline-flex align-center pa-2">
                <v-icon
                    :size="item.size"
                    class="nav-icon">
                    {{ item.icon }}
                </v-icon>
                <span class="nav-title ml-2">{{ item.title }}</span>
                <TechPreview
                    v-if="item.isTechPreview"
                    :label="item.techPreviewLabel"
                    class="ml-2" />
            </div>
        </div>
    </v-list-item>
</template>

<script>
    import TechPreview from 'components/common/TechPreview.vue'
    export default {
        name: 'SideNavItem',
        components: { TechPreview, },
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            matchedRoutes: {
                type: Array,
                default: () => ([]),
            },
        },
        computed: {
            shouldShowItem() {
                if (this.item?.showFlag && !this.$unleash.isFeatureEnabled(this.item?.showFlag)) {
                    return false
                }
                return true
            },
        },
        methods: {
            handleOnClick(item = {}) {
                this.$emit('onClick', item || this.item)
            },
        },
    }
</script>
