<template>
    <HelpToolTip
        :size="16">
        <template #message>
            <p>
                {{ $t('scuHoursTip') }}
            </p>
            <a
                :href="docLink"
                target="_blank">
                {{ $t('learn-more') }}
            </a>
        </template>
    </HelpToolTip>
</template>
<script>
    import { CONFIG } from 'config/index.js'
    import HelpToolTip from 'components/common/HelpTooltip'

    export default {
        name: 'ScuHelpTooltip',
        components: {
            HelpToolTip,
        },
        computed: {
            docLink() {
                return CONFIG.SKYSQL_INSTANCE_SIZES_DOCS_URL
            },
        },
    }
</script>
