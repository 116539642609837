<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="addAgent"
        :on-cancel="close"
        :min-width="1024"
        :max-width="1024"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6">
                {{ $t('copilot.manageAgents') }}
            </div>
        </template>
        <template #body>
            <div>
                <v-data-table
                    class="data-table-full"
                    :headers="headers"
                    :items="userAgents"
                    :hide-default-footer="userAgents.length <= 10"
                    fixed-header>
                    <template #item="{ item: agent }">
                        <tr :class="agent.id === selectedAgent.id ? 'v-data-table__selected' : ''">
                            <td>
                                <v-icon dense>
                                    mdi-account
                                </v-icon>
                                {{ agent.name || 'n/a' }}
                            </td>
                            <td>{{ getDatasourceName(agent.datasourceId) || 'n/a' }}</td>
                            <td>
                                <truncate-string
                                    max-width="300px"
                                    :text="agent.description || 'n/a'" />
                            </td>
                            <td :class="agent.status === 'DEPLOYED' ? 'success--text' : ''">
                                <span class="text-caption">
                                    {{ $t(`copilot.agentStatus.${agent.status}`) }}
                                </span>
                            </td>
                            <td>
                                <div style="white-space: nowrap;">
                                    <v-tooltip
                                        open-delay="400"
                                        top>
                                        <template #activator="{ on }">
                                            <v-btn
                                                color="success"
                                                :disabled="agent.id === selectedAgent.id"
                                                icon
                                                v-on="on"
                                                @click="chooseAgent(agent.id)">
                                                <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('select') }}</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        open-delay="400"
                                        top>
                                        <template #activator="{ on }">
                                            <v-btn
                                                color="primary"
                                                icon
                                                v-on="on"
                                                @click="editAgent(agent.id)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('edit') }}</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        open-delay="400"
                                        top>
                                        <template #activator="{ on }">
                                            <v-btn
                                                color="error"
                                                icon
                                                v-on="on"
                                                @click="confirmDeletion(agent.id, agent.contextId)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('delete') }}</span>
                                    </v-tooltip>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('close') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                style="min-width: 100px;"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('create') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotAgentsManageModal',
        data() {
            return {
                isDialogOpen: false,
                headers: [
                    { text: this.$t('name'), value: 'name', sortable: false, },
                    { text: this.$tc('copilot.datasource', 1), value: 'datasource', sortable: false, },
                    { text: this.$t('description'), value: 'description', sortable: false, },
                    { text: this.$t('status'), value: 'status', sortable: false, },
                    { text: this.$t('actions'), value: 'actions', sortable: false, }
                ],
            }
        },
        computed: {
            ...mapGetters([
                'agents',
                'selectedAgent',
                'datasources'
            ]),
            userAgents() {
                return this.agents.filter(agent => agent.type === 'USER')
            },
        },
        methods: {
            ...mapActions([
                'selectAgent'
            ]),
            open() {
                this.isDialogOpen = true
            },
            close() {
                this.isDialogOpen = false
            },
            addAgent() {
                this.close()
                this.$emit('addAgent')
            },
            chooseAgent(agentId) {
                this.close()
                this.selectAgent(agentId)
            },
            editAgent(agentId) {
                this.close()
                this.$emit('editAgent', agentId)
            },
            confirmDeletion(agentId, contextId) {
                this.$emit('removeAgent', { agentId, contextId, })
            },
            getDatasourceName(datasourceId) {
                const datasource = this.datasources.find(datasource => datasource.id === datasourceId)
                return datasource?.name || null
            },
        },
    }
</script>
