<style lang="scss" scoped>
    .context-menu {
        height: 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        > div.tables-menu {
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .v-input ::v-deep .v-input__control {
            .v-input__slot {
                min-height: unset;
            }
            .v-input__append-inner {
                margin-top: 6px;
            }
            .v-label {
                font-size: 14px;
            }
        }
    }
    .v-list-item {
        min-height: auto;
    }
    .table.v-list-item {
        .v-list-item__content {
            padding: 6px 0;
        }
    }
</style>

<template>
    <div
        v-if="tables.length"
        class="context-menu">
        <div>
            <v-text-field
                v-model="search"
                outlined
                dense
                clearable
                hide-details
                height="36"
                :label="$t('copilot.filterTables')"
                append-icon="mdi-magnify"
                @click:clear="() => search = ''" />
        </div>
        <div class="tables-menu">
            <v-list class="pt-0">
                <v-list-item
                    v-for="(table, i) in filteredTables"
                    :key="i"
                    :class="['px-0 my-1 table']">
                    <v-list-item-content>
                        <v-list-item-title :class="['pl-6']">
                            <v-icon>
                                mdi-table
                            </v-icon>
                            <span class="text-body-2 pl-1">{{ table }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </div>
    <div
        v-else
        class="text-center pt-4">
        {{ $t('copilot.emptySchema') }}
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'CopilotSchemaViewer',
        data () {
            return {
                search: '',
            }
        },
        computed: {
            ...mapGetters([
                'selectedDatasource',
                'selectedDatasourceSchema'
            ]),
            tables() {
                const schema = this.selectedDatasource?.schemas?.find(schema => schema.name === this.selectedDatasourceSchema)
                if (!schema || !schema.tables) {
                    return []
                } else {
                    return schema.tables
                }
            },
            filteredTables() {
                return this.tables
                    .filter(table => table.toLowerCase().includes(this.search?.toLowerCase() || ''))
            },
        },
    }
</script>
