import i18n from 'plugins/i18n'

export function emailInputRules (isRequired = true, minLength = 0, maxLength = 0) {
    return [
        value => isRequired ? (!!value || i18n.t('errors.emailRequired')) : true,
        value => maxLength > 0 ? ((value || '').length <= maxLength || i18n.t('errors.maxLength', [maxLength])) : true,
        value => minLength > 0 ? ((value || '').length >= minLength || i18n.t('errors.minLength', [minLength])) : true,
        value => {
            if (!value) return true
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || i18n.tc('errors.invalidEmail', 1, { email: value, })
        }
    ]
}
