export function serviceDiskSizeUsedBy ({ id, diskPurpose, byServer, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(kubelet_volume_stats_used_bytes{namespace="${id}", disk_purpose="${diskPurpose}"})${byServer ? ' by (kubernetes_pod_name)' : ''}`
    }
}

export function serviceDiskSizeCapacityBy ({ id, diskPurpose, byServer, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(kubelet_volume_stats_capacity_bytes{namespace="${id}", disk_purpose="${diskPurpose}"})${byServer ? ' by (kubernetes_pod_name)' : ''}`
    }
}

export function serviceDiskSizeUsageBy ({ id, diskPurpose, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum((kubelet_volume_stats_used_bytes{namespace="${id}", disk_purpose="${diskPurpose}"})/kubelet_volume_stats_capacity_bytes{namespace="${id}", disk_purpose="${diskPurpose}"}) by (kubernetes_pod_name)`
    }
}

export function serviceDiskSizeUsedByDataCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="data"}) by (kubernetes_pod_name)`
    }
}

export function serviceDiskSizeCapacityByDataCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="data"})`
    }
}

export function serviceDiskSizeUsedByLogsCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="logs"}) by (kubernetes_pod_name)`
    }
}

export function serviceDiskSizeCapacityByLogsCs ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="logs"})`
    }
}

export function serverDiskSizeUsedByData ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="data"}) by (kubernetes_pod_name)`
    }
}

export function serverDiskSizeCapacityByData ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="data"})`
    }
}

export function serverDiskSizeUsedByLogs ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="logs"}) by (kubernetes_pod_name)`
    }
}

export function serverDiskSizeCapacityByLogs ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="logs"})`
    }
}

export function xpandCapacityTotalUsagePercent ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_capacity_disk_total_usage_percent{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSpaceUsage ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_capacity_disk_total_usage_percent{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandIoDiskLatencySeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_io_disk_latency_seconds{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"}[300s])) by (kubernetes_pod_name, op)`
    }
}

export function xpandIoDiskLatencySecondsMax ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(rate(mariadb_xpand_io_disk_latency_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s])) by (op)`
    }
}

export function xpandCapacityDiskSystemMaxBytesUsage ({ name, id, type = 'mariadb', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum((mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"} - mariadb_xpand_capacity_disk_system_avail_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"})/(mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"} * 1.2)) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSystemMaxBytesCapacity ({ name, id, type = 'mariadb', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"} * 1.2) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSystemMaxBytesUsed ({ name, id, type = 'mariadb', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace="${id}"} - mariadb_xpand_capacity_disk_system_avail_bytes{${type}=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSystemUsageRatio ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_capacity_disk_system_usage_ratio{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}
