import { t } from 'typy'
import { CONFIG } from 'config/'
import observability from 'api/observability.js'
import { sortArrayObjectsAlphabetically } from 'helpers/'
import { aggregateQueries, filterLogsForTimeRange, readZipFile, slowQueryLogParser } from 'helpers/parseLogs'

function sortTopologyServersAlphabetically({ servers, } = {}) {
    if (!t(servers).isArray) return []

    return servers.sort(sortArrayObjectsAlphabetically('name'))
}

export function mapServiceToCortexRegion(regions, serviceRegion) {
    const { region, } = regions.find(({ name, }) => name === serviceRegion)
    return CONFIG.CORTEX_REGIONS[region]
}

export function mapTopologyStatus(services, topologies) {
    if (!t(services).isArray) return []
    if (!t(topologies).isArray) return services

    services.forEach(service => {
        const { id, namespace, topologyStatus, serverType, } = (topologies.find(({ namespace, }) => namespace === service.id) || {})
        if (id) service.topologyId = id
        if (namespace) service.namespace = namespace
        if (topologyStatus) service.topologyStatus = topologyStatus
        if (serverType) service.serverType = serverType
    })

    return services
}

export function mapTopologyServers(services, servers) {
    if (!t(services).isArray) return []
    if (!t(servers).isArray) return services

    servers.forEach(server => {
        const service = services.find(({ topologyId, }) => topologyId === server.topologyId)
        if (service) service.servers.push(server)
    })

    return services
}

export async function fetchTopologies() {
    return observability.get('services').then((response) => response.data)
}

export async function fetchTopologyServers() {
    return observability.get('servers').then((response) => sortTopologyServersAlphabetically(response.data))
}

export async function queryCortex(promQL = '', cortexRegion = '', track = '') {
    /* The following endpoint evaluates an instant query at a single point in time:
        query=<string>: Prometheus expression query string.
        time=<rfc3339 | unix_timestamp>: Evaluation timestamp. Optional.
    */
    const params = new URLSearchParams({
        query: promQL,
        time: (new Date().getTime() / 1000).toString(),
        track,
    })
    return observability.get(`query?${params}`, {}, {
        headers: {
            'X-Observability-Region': cortexRegion,
        },
    }).then((response) => response.data.data)
}

export async function queryCortexRange(promQL = '', cortexRegion = '', time = [], step = 60, track = '') {
    /* The following endpoint evaluates an expression query over a range of time:
        query=<string>: Prometheus expression query string.
        start=<rfc3339 | unix_timestamp>: Start timestamp, inclusive.
        end=<rfc3339 | unix_timestamp>: End timestamp, inclusive.
        step=<duration | float>: Query resolution step width in duration format or float number of seconds.
    */
    const params = new URLSearchParams({
        query: promQL,
        start: (time[0] / 1000).toString(),
        end: (time[1] / 1000).toString(),
        step: step.toString(),
        track,
    })
    return observability.get(`query_range?${params}`, {}, {
        headers: {
            'X-Observability-Region': cortexRegion,
        },
    }).then((response) => response.data.data)
}

async function fetchLogs(params) {
    return observability.post('logs/query', params).then((response) => response.data)
}

/**
 * @param {String} id - log id
 * @returns {Promise<blob>} zip file contains a raw log file
 */
async function fetchLogBlob(id) {
    return observability.get('logs/archive', {
        logIds: id,
        logFormat: 'raw',
    }, {
        responseType: 'blob',
    }).then((response) => response.data)
}

/**
 * @param {Array<string>} logIds - log ids
 * @returns {Promise<object>} The contents of logs keyed by log id
 */
export async function getLogContents(logIds) {
    let fileContents = {}
    for (const id of logIds) {
        const blob = await fetchLogBlob(id)
        const files = await readZipFile(blob)
        const content = t(Object.values(files), '[0]').safeString
        fileContents[id] = content
    }
    return fileContents
}

async function queryTopSlowQueries(param) {
    const data = await fetchLogs(param)
    const logIds = t(data, 'logs').safeArray.map(log => log.id)
    let logs = []
    const logContents = await getLogContents(logIds)
    Object.values(logContents).forEach(content => {
        const slowQueries = slowQueryLogParser({ str: content,})
        const filterSlowQueries = filterLogsForTimeRange(slowQueries, param.fromDate, param.toDate)
        logs = [...logs, ...filterSlowQueries]
    })

    const aggregatedLogs = aggregateQueries(logs)

    return {
        result: aggregatedLogs,
    }
}

export async function queryObservability(metricName, params) {
    if (metricName === 'topSlowQueries') {
        return queryTopSlowQueries(params)
    }
}
