<template>
    <v-select
        :class="['std std--dropdown select-dropdown']"
        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
        :items="schemas"
        :value="selectedDatasourceSchema"
        :loading="areDatasourcesLoading"
        :disabled="areDatasourcesLoading"
        height="36"
        outlined
        attach
        single-line
        @change="changeDatasourceSchema">
        <template #selection="{ item }">
            <v-icon dense>
                mdi-table-multiple
            </v-icon>
            <span class="pl-1">
                {{ item }}
            </span>
        </template>
        <template #item="{ item }">
            <v-icon dense>
                mdi-table-multiple
            </v-icon>
            <span class="pl-1">
                {{ item }}
            </span>
        </template>
    </v-select>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotSchemasMenu',
        computed: {
            ...mapGetters([
                'areDatasourcesLoading',
                'selectedDatasource',
                'selectedDatasourceSchema',
                'agentChatSessions',
                'selectedAgent'
            ]),
            schemas() {
                if (!this.selectedDatasource?.id && !this.selectedDatasource.schemas) {
                    return []
                }
                return this.selectedDatasource.schemas.map(schema => schema.name)
            },
        },
        methods: {
            ...mapActions([
                'selectDatasourceSchema',
                'selectChatSession',
                'newChatSession'
            ]),
            changeDatasourceSchema(schema) {
                this.selectDatasourceSchema(schema)
                const chatSessionWithSchema = this.agentChatSessions.find(chat => chat.agentConfig?.dbName === schema)
                if (chatSessionWithSchema) {
                    this.selectChatSession(chatSessionWithSchema.id)
                } else {
                    this.newChatSession({
                        agentId: this.selectedAgent.id,
                        datasourceId: this.selectedDatasource.id,
                        datasourceSchema: this.selectedDatasourceSchema,
                    })
                }
            },
        },
    }
</script>
