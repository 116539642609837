<style lang="scss" scoped>
.payment-option {
    border: 1px solid $cornsilk
}
.payment-option:not(:first-child) {
    margin-top: 25px;
}
</style>
<template>
    <div class="mt-8">
        <v-row>
            <v-expansion-panels
                v-model="selectedOption"
                accordion>
                <!-- contact -->
                <v-expansion-panel>
                    <AccordianPanelHeader
                        :show-tool-tip="false"
                        :title="$t('billings.contacts')" />
                    <v-expansion-panel-content>
                        <ContactManagement />
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- tax -->
                <v-expansion-panel>
                    <AccordianPanelHeader
                        :show-tool-tip="false"
                        :title="$t('billings.taxInformation')" />
                    <v-expansion-panel-content>
                        <TaxManagement />
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- payment -->
                <v-expansion-panel>
                    <AccordianPanelHeader
                        :show-tool-tip="false"
                        :title="$t('billings.paymentMethods')" />
                    <v-expansion-panel-content>
                        <PaymentManagement />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </div>
</template>

<script>
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import TaxManagement from 'components/billing/taxManagement/TaxManagement.vue'
    import ContactManagement from 'components/billing/contactManagement/ContactManagement.vue'
    import PaymentManagement from 'components/billing/paymentManagement/PaymentManagement.vue'

    export default {
        name: 'Invoice',
        components: {
            AccordianPanelHeader,
            TaxManagement,
            ContactManagement,
            PaymentManagement,
        },
        data() {
            return {
                options: ['contact-management', 'tax-management', 'payment-methods'],
                selectedOption: null,
            }
        },
    }
</script>
