<template>
    <v-select
        :class="['std std--dropdown select-dropdown']"
        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
        :items="agents"
        item-text="name"
        item-value="id"
        :value="selectedAgent?.id"
        :loading="areAgentsLoading || areDatasourcesLoading"
        :disabled="areAgentsLoading || areDatasourcesLoading"
        :error="agentsError"
        height="36"
        outlined
        attach
        single-line
        @change="changeAgent">
        <template #selection="{ item }">
            <v-icon dense>
                {{ item.type === 'SYSTEM' ? 'mdi-shield-account' : 'mdi-account' }}
            </v-icon>
            <span class="pl-1">
                {{ item.name }}
            </span>
        </template>
        <template #item="{ item }">
            <v-icon dense>
                {{ item.type === 'SYSTEM' ? 'mdi-shield-account' : 'mdi-account' }}
            </v-icon>
            <span class="pl-1">
                {{ item.name }}
            </span>
        </template>
    </v-select>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotAgentsMenu',
        computed: {
            ...mapGetters([
                'agents',
                'agentsError',
                'areAgentsLoading',
                'selectedAgent',
                'areDatasourcesLoading',
                'selectedDatasource'
            ]),
        },
        methods: {
            ...mapActions([
                'selectAgent',
                'selectDatasource'
            ]),
            changeAgent(agentId) {
                this.selectAgent(agentId)
                if (this.selectedAgent.type === 'USER') {
                    this.selectDatasource(this.selectedAgent.datasourceId)
                }
                const shouldShowReadme = this.selectedAgent.hasMoreInfo && localStorage.getItem(`copilot-agent-${agentId}`) !== 'true'
                if (shouldShowReadme) {
                    this.$emit('showAgentInfo', agentId)
                }
            },
        },
    }
</script>
