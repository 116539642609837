<template>
    <v-expansion-panel
        id="topologyPanel"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="$t('launchService.type')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <div>{{ $t('launchService.topologyInfo') }}</div>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <div
                v-if="!serviceTypes.length"
                class="text-center pt-3">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </div>
            <v-row
                :class="['minimized-icon-boxes mt-4 mb-4', { 'd-none': serviceTypes.length < 2 || !$unleash.isFeatureEnabled('enable-serverless') }]">
                <v-col
                    v-for="(serviceDetails, index) in serviceTypes"
                    :key="serviceDetails.id"
                    class="pa-0"
                    cols="6">
                    <MinimizedDatabaseTypeCard
                        :id="serviceDetails.id"
                        :title="serviceDetails.title"
                        :icon="serviceDetails.icon"
                        :type="serviceDetails.name"
                        :tech-preview="serviceDetails.isTechPreview"
                        :free-tier="serviceDetails.isFreeTier"
                        :icon-size="40"
                        :parent-data-auto-prefix="dataAutoPrefix"
                        :selected-id="selectedId"
                        :is-position-right="Boolean(!index%2)"
                        @handleDatabaseRequirementSelection="handleDatabaseRequirementSelection" />
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row
                    align="center"
                    justify="center"
                    class="topology-wrapper">
                    <v-col cols="mt-3 mb-3 wrapper-container">
                        <div
                            v-if="isTopologyFetching"
                            class="text-center">
                            <v-progress-circular
                                size="20"
                                width="7"
                                color="primary"
                                indeterminate />
                        </div>
                        <v-row v-else>
                            <TopologyCard
                                v-for="topologyDetails in matchedTopologies"
                                :id="topologyDetails.id"
                                :key="topologyDetails.type"
                                :title="getTopologyTitle(topologyDetails)"
                                :server-type="topologyDetails.serverType"
                                :type="topologyDetails.name"
                                :description="topologyDetails.description"
                                :features="topologyDetails.features"
                                :icon="topologyDetails.icon"
                                :icon-size="topologyDetails.iconSize"
                                :current-topology-id="currentTopologyId"
                                :col-span="matchedTopologies.length > 1 ? 6 : 12"
                                :is-tech-preview="topologyDetails.isTechPreview"
                                @handleTopologySelection="
                                    handleTopologySelection
                                " />
                        </v-row>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import TopologyCard from 'components/launchService/TopologyCard.vue'
    import MinimizedDatabaseTypeCard from 'components/launchService/MinimizedDatabaseTypeCard.vue'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'

    export default {
        name: 'TopologySelection',
        components: {
            TopologyCard,
            MinimizedDatabaseTypeCard,
            AccordianPanelHeader,
        },
        props: {
            isTopologySelected: Boolean,
            parentDataAutoPrefix: {
                type: Function,
                default: () => {},
            },
        },
        data: function () {
            return {
                currentTopologyId: null,
                selectedId: null,
                matchedTopologies: [],
            }
        },
        computed:{
            ...mapGetters(['serviceTypes','topologiesDetails', 'isTopologyFetching', 'isServiceTypesFetching']),
        },
        methods: {
            ...mapActions(['fetchServiceTypes','fetchTopologyDetails']),
            handleDatabaseRequirementSelection ({ id, }) {
                const selectedService = this.serviceTypes.find(service => service.id === id)
                this.matchedTopologies = this.topologiesDetails.filter(topology=> topology.service_type === selectedService.name)
                this.selectedId = id
                this.$emit('onRequirementOfDBSelect', selectedService)
                if (this.matchedTopologies[0]) {
                    this.handleTopologySelection(this.matchedTopologies[0].id)
                }
            },
            handleTopologySelection (id) {
                this.currentTopologyId = id
                const selectedTopology = this.topologiesDetails.find(topology =>
                    topology.id === id
                )
                this.$emit('onTopologySelect', selectedTopology)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(`topologyPanel${string ? '-' + string : ''}`)
            },
            getTopologyTitle(topology) {
                return topology.title
            },
        },
        async created () {
            if (this.$lodash.isEmpty(this.serviceTypes)) {
                await this.fetchServiceTypes()
            }
            if (this.$lodash.isEmpty(this.topologiesDetails)) {
                await this.fetchTopologyDetails()
            }
            // Preselect options
            const shouldSelectServerless = this.$unleash.isFeatureEnabled('enable-serverless')
            const service = this.serviceTypes?.find(
                service => (shouldSelectServerless ? service.name?.includes('serverless') : service.name === 'transactional')
            )
            if (service) {
                this.handleDatabaseRequirementSelection(service)
            }
        },
    }
</script>
