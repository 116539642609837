<style lang="scss" scoped>
    .q-cards {
        width: 100%;
        min-height: 100%;
        padding: 8px 0;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: min-content;
        align-items: center;
        align-content: center;
        gap: 20px;
        .v-card {
            display: flex;
            border-radius: 10px;
            min-height: 120px;
            align-items: center;
            justify-content: center;
        }
    }
    .q-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
    }
</style>

<template>
    <div
        v-if="questions.length && !dense"
        class="q-cards">
        <v-card
            v-for="(q, i) in questions"
            :key="i"
            class="pa-4 pointer"
            outlined
            @click="selectQuestion(q.prompt || q.summary)">
            <div>
                {{ q.text || q.summary }}
            </div>
        </v-card>
    </div>
    <v-tabs v-else-if="questions.length && dense">
        <div class="q-buttons">
            <v-btn
                v-for="(q, i) in questions"
                :key="i"
                color="info"
                class="text-none txt-compact"
                outlined
                small
                @click="selectQuestion(q.prompt || q.summary)">
                {{ q.text || q.summary }}
            </v-btn>
        </div>
    </v-tabs>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'CopilotChatStarterQuestions',
        props: {
            dense: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters([
                'selectedAgent'
            ]),
            questions() {
                return this.$lodash.concat(
                    this.selectedAgent?.starterQuestions || [],
                    this.selectedAgent?.queries || []
                ) || []
            },
        },
        methods: {
            selectQuestion(question) {
                this.$emit('select', question)
            },
        },
    }
</script>
