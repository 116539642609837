import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from 'store/index'

const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

apiClient.interceptors.request.use(function (config) {
    // Check if unauth request made for other service API's
    // this will remove the dependency to clear out
    // unhandled polling api calls when session is terminated
    const isSessionUrl = config.url.includes('iam/v1/session')
    if(!store.getters.authorized && !isSessionUrl){
        const controller = new AbortController()
        const signal = controller.signal
        let cfg = {
            ...config,
            signal,
        }
        controller.abort()
        return cfg
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

apiClient.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        if (error.response?.status === 401 && !store.getters.isRefreshingToken) {
            await store.dispatch('getRefreshToken', { root: true, })
            if (store.getters.authError) {
                store.commit('setUserAuthorized', false)
            }
        }
        return Promise.reject(error)
    }
)

Vue.use(VueAxios, apiClient)
