import { mapGetters, mapActions, mapState } from 'vuex'
import { isSingleNodeTopology } from 'utils/service'
import { isServerlessTopology } from 'utils/service'

export default {
    computed: {
        ...mapState(['manageServices']),
        ...mapGetters(['getServiceById']),
        canScaleStorage() {
            const { service_type: serviceType, provider, } = this.service
            return (
                !(
                    serviceType === this.$config.ANALYTICAL &&
                    provider === this.$config.cloudProvider.GCP
                )
            )
        },
    },
    methods: {
        ...mapActions([
            'fetchPoliciesForService',
            'fetchNodeSizes',
            'fetchStorageSizes',
            'fetchVerticalScaleNodeSizes',
            'setServiceDepsFetching',
            'openAutonomousDialog'
        ]),
        async fetchAutoScaleDependencies() {
            this.setServiceDepsFetching(true)
            // eslint-disable-next-line camelcase
            const { provider, service_type, architecture, size, topology, nodes, } = this.service
            let dependencies = []
            if (!isSingleNodeTopology(topology, nodes))
                dependencies.push(this.fetchNodeSizes(topology))

            if (this.canScaleStorage)
                dependencies.push(this.fetchStorageSizes(topology))

            !isServerlessTopology(topology) && dependencies.push(
                this.fetchVerticalScaleNodeSizes({
                    // eslint-disable-next-line camelcase
                    service_type,
                    provider,
                    ...(provider === this.$config.cloudProvider.AWS && {
                        architecture,
                    }),
                    // eslint-disable-next-line camelcase
                    min_node_size: size,
                })
            )

            await Promise.all(dependencies)
            this.setServiceDepsFetching(false)
        },
    },
}
