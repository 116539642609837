<style lang="scss" scoped>
.recommended-text {
    color: $accent;
    font-style: italic;
}

.maintenance-window-width {
    width: 330px !important;
}

.tech-position {
    position: absolute;
}
</style>
<template>
    <v-expansion-panel
        id="serverPanel"
        :disabled="isDisabled"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="getLocaleFor('serviceAttributes')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <template #tooltip-content>
                <div>{{ $t('launchService.serverInfo') }}</div>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <div v-if="!isServerVersionFetching">
                <div class="mt-4">
                    <v-row v-if="sortedServerList.length">
                        <v-col cols="6">
                            <label>{{ getLocaleFor('version') }}</label>
                            <v-select
                                v-model="selectedServer"
                                :value="selectedServer"
                                :items="sortedServerList.map(x => x.display_name)"
                                :dense="true"
                                :disabled="!canChangeVersion"
                                return-object
                                outlined
                                @change="handleServerSelection" />
                        </v-col>
                        <v-col cols="6">
                            <div class="mt-6">
                                <a @click="openNotesUrl()">{{
                                    getLocaleFor('release-notes')
                                }}</a>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <v-row class="mt-4">
                    <v-col :data-auto="dataAutoPrefix('content-serviceName')">
                        <label>{{ getLocaleFor("serviceName") }}</label>
                        <v-form
                            ref="serviceForm"
                            v-model="valid"
                            @submit.prevent="focusOnMaintenanceWindow">
                            <v-text-field
                                v-model="serviceName"
                                solo
                                outlined
                                dense
                                flat
                                :rules="[rules.required, rules.validService, rules.maxLength, rules.minLength]"
                                :data-auto="dataAutoPrefix('content-serviceName-input')"
                                @keyup="handleServiceNameChange" />
                        </v-form>
                    </v-col>
                    <v-col>
                        <div class="pt-4 text-body-2">
                            {{ getLocaleFor('pass-validation-text') }}
                        </div>
                    </v-col>
                </v-row>
                <div
                    v-if="isPowerTierUser"
                    class="mt-4"
                    :data-auto="dataAutoPrefix('content-maintenanceWindow')">
                    <v-row>
                        <v-col>
                            {{ getLocaleFor("selectMaintenanceWindow") }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <v-select
                                    ref="maintenanceWindow"
                                    v-model="selectedMaintenanceWindow"
                                    :class="['std std--dropdown select-dropdown maintenance-window-width']"
                                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                                    :items="maintenanceWindowList"
                                    item-value="display_name"
                                    item-text="display_name"
                                    height="36"
                                    outlined
                                    attach
                                    single-line
                                    required
                                    @change="onMaintenanceWindowSelect" />
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div
                v-else
                class="text-center pt-3">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapGetters } from 'vuex'
    import i18n from 'plugins/i18n'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import { sortServerVersions } from 'utils/service'
    export default {
        name: 'ServerSelection',
        components: { AccordianPanelHeader, },
        props: {
            getLocaleFor: {
                type: Function,
                default: (txt) => txt,
            },
            serverList: {
                type: Array,
                default: () => ([]),
            },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            defaultServiceName: {
                type: String,
                required: true,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
            region: {
                type: String,
                default: '',
            },
            canChangeVersion: {
                type: Boolean,
                default: true,
            },
            isPowerTierUser: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                selectedServer: '',
                serviceName: this.defaultServiceName,
                rules: {
                    required: value => !!value || i18n.t('errors.required'),
                    validService: value => {
                        const pattern = /^[a-z][a-z0-9-]*[a-z0-9]$/
                        return pattern.test(value) || i18n.t('errors.invalidServiceName')
                    },
                    maxLength: value => (!!value && value.length <= 24) || i18n.t('errors.serviceNameMaxLength', [24]),
                    minLength: value => (!!value && value.length >= 4) || i18n.t('errors.serviceNameMinLength', [4]),
                },
                valid: false,
                selectedMaintenanceWindow: '',
            }
        },
        computed: {
            ...mapGetters(['isServerVersionFetching', 'maintenanceWindowList']),
            sortedServerList() {
                return sortServerVersions(this.serverList)
            },
        },
        watch: {
            defaultServiceName(newValue) {
                // Reset the model to set as parent
                // The props update is not repsected by v-text-field
                // component so adding this change
                if (newValue === '') this.serviceName = newValue
            },
            maintenanceWindowList() {
                if (this.maintenanceWindowList[0]) {
                    this.selectedMaintenanceWindow = this.maintenanceWindowList[0].display_name
                }
            },
            serverList() {
                // TODO: Hard-coded for now, remove once DPS supports ordering
                const indexVer1011 = this.sortedServerList?.findIndex(server => server.version === '10.11.8-1')
                const defaultVersion = this.sortedServerList?.at(indexVer1011 >= 0 ? indexVer1011 : 0)
                if (defaultVersion) {
                    this.selectedServer = defaultVersion.display_name
                    this.$emit('onMariaDBServerSelect', defaultVersion)
                }
            },
        },
        methods: {
            handleServerSelection() {
                const server = this.serverList.find(server => server.display_name === this.selectedServer)
                this.$emit('onMariaDBServerSelect', server)
            },
            async handleServiceNameChange() {
                this.$refs.serviceForm.validate()
                // There is some delay in flipping the valid flag
                // so adding this to workaround that lag
                await this.$nextTick()
                this.$emit('onServiceNameEntered', { name: this.serviceName, hasErrors: !this.valid, })
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    `servePanel${string ? '-' + string : ''}`
                )
            },
            openNotesUrl() {
                const url = this.serverList.find(server => server?.display_name === this.selectedServer)?.release_notes_url
                window.open(url, '_blank')
            },
            onMaintenanceWindowSelect(value) {
                const foundWindow = this.maintenanceWindowList.find(maintenanceWindow => maintenanceWindow.display_name === value)
                this.$emit('onMaintenanceWindowSelect', foundWindow.name)
            },
            focusOnMaintenanceWindow() {
                if (this.$refs.maintenanceWindow) this.$refs.maintenanceWindow.focus()
            },
        },
    }
</script>
