<style lang="scss" scoped>
.billing-navigation {
    border-bottom: 1px solid #e8eef1;

    a {
        text-decoration: none;
        height: 40px;
        line-height: 40px;
        display: block;
        font-size: 14px;
    }

    .router-link-active:not(.settings) {
        border-bottom: 3px solid $primary;
        font-weight: bold;
    }
}
</style>

<template>
    <v-row class="d-flex wrapper-container justify-space-between">
        <div class="view-headline">
            {{ $t("billing") }}
        </div>

        <div class="billing-navigation">
            <div class="route-tabs d-flex">
                <router-link
                    class="mx-2 px-3"
                    to="/billing/usage">
                    {{ $t('billings.currentUsage') }}
                </router-link>
                <router-link
                    class="mx-2 px-3"
                    to="/billing/history">
                    {{ $t('billings.billingHistory') }}
                </router-link>
                <router-link
                    class="mx-2 px-3"
                    to="/billing/profile">
                    {{ $t('billings.billingProfile') }}
                </router-link>
            </div>
        </div>
    </v-row>
</template>

<script>
    export default {
        name: 'BillingNavigation',
    }
</script>
