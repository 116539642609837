<style lang="scss">
iframe {
    width: 100% !important;
}

.field-required {
    &:before {
        content: "* ";
        color: $required-asterisk;
    }
}

.otp-wrapper {
    width: 300px;
}

.StripeElement {
    border: 1px solid #00000061;
    border-radius: 4px;
    height: 38px;
    padding: 8px;
}

.StripeElement--focused {
    border: 2px solid rgb(99, 83, 239)
}

.StripeElement--invalid {
    border: 1px solid #D22F27
}

.info-text {
    color: rgb(99, 83, 239);
    font-size: smaller;
    line-height: normal;
}
</style>

<template>
    <div>
        <div class="payment-option">
            <base-dialog
                v-model="isPaymentOpen"
                :min-width="800"
                :max-width="800"
                :title="isForEdit ? $t('billings.editPaymentMethod') : $t('billings.addNewCreditCard')"
                :on-close="closePaymentModal"
                :on-cancel="closePaymentModal">
                <template #body>
                    <div :class="['pt-6', 'pl-6', 'pr-6', {'disable-dialog': isSaving}]">
                        <div class="mb-7">
                            {{ $t('billings.paymentNote') }}
                        </div>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.name') }}</label>
                                <v-text-field
                                    v-model="name"
                                    outlined
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row v-if="isForEdit">
                            <v-col
                                class="py-0">
                                <label class="required text-uppercase">{{ $t('billings.cardNumber') }}</label>
                                <v-text-field
                                    :value="maskedCCNumber"
                                    disabled
                                    readonly
                                    outlined
                                    dense />
                            </v-col>
                            <v-col
                                cols="2"
                                class="py-0">
                                <label class="required text-uppercase">{{ `${$t('billings.month')}/${$t('billings.year')}` }}</label>
                                <v-text-field
                                    v-model="cardExpiration"
                                    outlined
                                    dense
                                    :rules="expiryRules"
                                    @input="formatExpiryDate" />
                            </v-col>
                            <v-col
                                cols="2"
                                class="py-0">
                                <label class="required text-uppercase">{{ $t('billings.cvv') }}</label>
                                <v-text-field
                                    value="***"
                                    disabled
                                    readonly
                                    outlined
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col
                                class="py-0">
                                <label class="required text-uppercase">{{ $t('billings.cardNumber') }}</label>
                                <div id="card-number"></div>
                            </v-col>
                            <v-col
                                cols="2"
                                class="py-0">
                                <label class="required text-uppercase">{{ `${$t('billings.month')}/${$t('billings.year')}` }}</label>
                                <div id="card-expiry"></div>
                            </v-col>
                            <v-col
                                cols="2"
                                class="py-0">
                                <label class="required text-uppercase">{{ $t('billings.cvv') }}</label>
                                <div id="card-cvc"></div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex align-center">
                                <v-icon
                                    size="16"
                                    color="rgb(99, 83, 239)">
                                    mdi-information-outline
                                </v-icon>
                                <span class="ml-1 info-text">{{ $t('billings.ccDetailsInfo') }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.country') }}</label>
                                <v-select
                                    v-model="currentCountry"
                                    :items="sortedCountries"
                                    item-text="name"
                                    item-val="iso31662"
                                    height="40"
                                    :dense="true"
                                    class="mt-0 select-usage"
                                    outlined
                                    return-object
                                    @change="changeCountry" />
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <template #actions="{ cancel }">
                    <v-switch
                        v-if="isForEdit"
                        v-model="isDefault"
                        :label="$t('billings.setDefault')"
                        color="primary"
                        class="ma-0 pa-0"
                        hide-details
                        small />

                    <v-spacer />

                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="!isPaymentformValid"
                        :loading="isSaving"
                        @click.prevent="isForEdit ? submitUpdateData() : submitCCData()">
                        <span class="text-none"> {{ $t('save') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
    </div>
</template>

<script>
    import to from 'await-to-js'
    import { setupIntent } from 'services/billings'
    import { mapGetters, mapMutations, mapActions } from 'vuex'

    const elementStyles = {
        base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: '"Azo Sans", sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#afb5ba',
            },
            ':-webkit-autofill': {
                color: '#e39f48',
            },
        },
        invalid: {
            color: '#E25950',
            '::placeholder': {
                color: '#FFCCA5',
            },
        },
    }
    const elementClasses = {
        focus: 'StripeElement--focused',
        invalid: 'StripeElement--invalid',
    }

    export default {
        name: 'Payment',
        props: {
            legalEntityIdentifier: {
                type: String,
                required: true,
            },
            product: {
                type: String,
                default: '',
            },
            primaryContactCountry: {
                type: String,
                default: '',
            },
            isForEdit: {
                type: Boolean,
                default: false,
            },
            paymentMethodForEdit: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                name: null,
                isSaving: false,
                stripe: null,
                elements: null,
                cardNumber: null,
                intent: null,
                publishableKey: null,
                returnUrl: null,
                secret: null,
                isCardNumberValid: false,
                isCardExpiryValid: false,
                isCardCvcValid: false,
                isDefault: false,
                country: null,
                currentCountry: {},
                cardExpiration: null,
                isValidExpiry: true,
                expiryRules: [
                    value => {
                        const today = this.$moment()
                        const expiryDate = this.$moment(value, 'MM/YY')
                        this.isValidExpiry = expiryDate.isValid() && expiryDate.isAfter(today)
                        return this.isValidExpiry || ''
                    }
                ],
            }
        },
        computed: {
            ...mapGetters([
                'isPaymentDialogOpen',
                'user',
                'orgData',
                'isFetchingPaymentDetail',
                'billingRecord',
                'billingCountries'
            ]),
            isPaymentOpen: {
                get() {
                    return this.isPaymentDialogOpen
                },
                set(value) {
                    this.setPaymentDialog(value)
                },
            },
            sortedCountries(){
                const clonedCountries = JSON.parse(JSON.stringify(this.billingCountries))
                return clonedCountries.sort((a, b) => a.name.localeCompare(b.name))
            },
            isPaymentformValid() {
                return !!this.name && !!this.country &&
                    ((this.isForEdit && this.isValidExpiry) || this.isCardValid)
            },
            isCardValid() {
                return this.isCardNumberValid && this.isCardExpiryValid && this.isCardCvcValid
            },
            maskedCCNumber() {
                return this.$help.maskedCCNumber(this.paymentMethodForEdit?.card)
            },
            cardExpiryDate() {
                return `${this.paymentMethodForEdit?.card?.expirationMonth}/${this.paymentMethodForEdit?.card?.expirationYear?.toString().slice(-2)}`
            },
        },
        watch:{
            billingCountries(countries){
                this.country = this.country || 'US'
                this.currentCountry = countries.find(country => country.iso31662 === this.country)
            },
            isPaymentDialogOpen(value){
                if(value) {
                    this.$nextTick(async ()=>{
                        if (!this.isForEdit) {
                            await this.loadStripe()
                        }
                    })
                }
            },
        },
        methods: {
            ...mapMutations(['setPaymentDialog']),
            ...mapActions([
                'fetchCountries',
                'editPayment',
                'updatingPaymentSuccess'
            ]),
            formatExpiryDate(value) {
                this.cardExpiration = value.replace(
                    /[^0-9]/g, '' // To allow only numbers
                ).replace(
                    /^([2-9])$/g, '0$1' // To handle 3 > 03
                ).replace(
                    /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
                ).replace(
                    /^0{1,}/g, '0' // To handle 00 > 0
                ).replace(
                    /^([0-1]{1}[0-9]{1})([0-9]{0,2}).*/g, '$1/$2' // To handle 113 > 11/3
                )
            },
            async submitUpdateData() {
                this.isSaving = true
                const data = {}
                if (this.isDefault !== this.paymentMethodForEdit.defaultPaymentMethod) {
                    data.default = this.isDefault
                }
                if (this.name !== this.paymentMethodForEdit.billingDetails.name) {
                    data.billingDetails = {
                        name: this.name,
                    }
                }
                if (this.cardExpiration !== this.cardExpiryDate) {
                    data.expiry = {
                        month: Number(this.cardExpiration.split('/')[0]),
                        year: Number(this.$moment(this.cardExpiration.split('/')[1], 'YY').format('YYYY')),
                    }
                }
                if (this.country !== this.paymentMethodForEdit.billingDetails.country) {
                    data.billingDetails = {
                        country: this.country,
                    }
                }
                await to(this.editPayment({id: this.paymentMethodForEdit.paymentMethodId, data,}))
                if(this.updatingPaymentSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.paymentUpdatedSuccessfully'), type: '#03a128', })
                    this.pushSentryEvent('Payment method updated successfully', 'info')
                    this.$emit('on-update-profile')
                }
                else {
                    this.$store.commit('showMessage', { text: error?.message ?? this.$t('errors.updatePayment'), type: this.$config.messageType.ERROR, })
                    this.pushSentryEvent(error?.message ?? this.$t('errors.paymentUpdateError'), 'error')
                }
            },
            async submitCCData() {
                this.isSaving = true
                // eslint-disable-next-line no-unused-vars
                const [error, response] = await to(this.stripe.confirmCardSetup(this.secret, {
                    'payment_method': {
                        card: this.cardNumber,
                        'billing_details': {
                            name: this.name,
                            address: {
                                country: this.country,
                            },
                        },
                    },
                }
                ))
                if(response?.setupIntent) {
                    this.$store.commit('showMessage', { text: this.$t('billings.cardAddedSuccessfully'), type: '#03a128', })
                    this.pushSentryEvent('Card added successfully', 'info')
                    this.$emit('on-update-profile')
                }
                else {
                    this.$store.commit('showMessage', { text: response?.error?.message ?? this.$t('billings.cardSetupError'), type: this.$config.messageType.ERROR, })
                    this.pushSentryEvent(response?.error?.message ?? this.$t('billings.cardSetupError'), 'error')
                }
                this.isSaving = false
            },
            changeCountry(data){
                this.country = data.iso31662
            },
            clearState() {
                this.name = null
                this.isSaving = false
                this.stripe = null
                this.elements = null
                this.cardNumber = null
                this.intent = null
                this.publishableKey = null
                this.returnUrl = null
                this.secret = null
                this.country = null
                this.currentCountry = {}
                this.isCardNumberValid = false
                this.isCardExpiryValid = false
                this.isCardCvcValid = false
                this.isDefault = false
            },
            setupBillingDetails() {
                if (this.isForEdit) {
                    const billingDetails = this.paymentMethodForEdit?.billingDetails || {}
                    this.name = billingDetails.name || ''
                    this.country = billingDetails.country || 'US'
                    this.currentCountry = this.billingCountries?.find(country => country.iso31662 === this.country) || {}
                    this.isDefault = this.paymentMethodForEdit?.defaultPaymentMethod
                    this.cardExpiration = this.cardExpiryDate
                } else {
                    this.name = `${this.user.firstName} ${this.user.lastName}`
                }
            },
            closePaymentModal() {
                this.clearState()
                this.$emit('on-payment-close')
            },
            async loadStripe() {
                this.stripe = new Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
                this.elements = await this.stripe.elements({})
                this.cardNumber = await this.elements.create('cardNumber', {
                    style: elementStyles,
                    classes: elementClasses,
                    showIcon: true,
                })
                this.cardNumber.mount('#card-number')
                this.cardNumber.on('change', (event) => {
                    this.setElementValidationStatus(event)
                })

                const cardExpiry = await this.elements.create('cardExpiry', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardExpiry.mount('#card-expiry')
                cardExpiry.on('change', (event) => {
                    this.setElementValidationStatus(event)
                })

                const cardCvc = await this.elements.create('cardCvc', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardCvc.mount('#card-cvc')
                cardCvc.on('change', (event) => {
                    this.setElementValidationStatus(event)
                })
            },
            setElementValidationStatus(event) {
                switch(event?.elementType) {
                    case 'cardNumber':
                        this.isCardNumberValid = event?.complete
                        break
                    case 'cardExpiry':
                        this.isCardExpiryValid = event?.complete
                        break
                    case 'cardCvc':
                        this.isCardCvcValid = event?.complete
                        break
                    default:
                }
            },
            pushSentryEvent(msg, eventLevel) {
                this.$sentry.captureEvent({ message: msg, user: { id: this.user.id, username: this.user.name, }, level: eventLevel,})
            },
        },
        async mounted(){
            this.setupBillingDetails()
        },
        async created() {
            if (!this.isForEdit) {
                const [error, response] = await to(setupIntent())
                if (!error) {
                    ({intent: this.intent, returnUrl: this.returnUrl, secret: this.secret,} = response)
                } else {
                    this.$store.commit('showMessage', { text: this.$t('billings.setupIntentError'), type: this.$config.messageType.ERROR, })
                    this.pushSentryEvent(this.$t('billings.setupIntentError'), 'error')
                }
            }
        },
    }
</script>
