<style lang="scss" scoped>
.header {
    background-color: $portal-header-bg!important;

    .product-link {
        display: block;
    }

    div.product-title {
        float: right;
        font-weight: 500;
        font-size: 18px;
        display: inline;
        margin-top: 0.5rem;
    }
}

.v-btn {
    letter-spacing: normal;
}

.v-menu__content {
    border-radius: 0;

    & .v-list {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
    }
}
</style>

<template>
    <v-app-bar
        class="header"
        clipped-left
        fixed
        app
        flat
        dark>
        <v-toolbar-title class="pl-8">
            <a
                class="product-link"
                to="/dashboard">
                <img
                    class="float-left"
                    height="58"
                    src="@/assets/logo_darkmode.png"
                    alt="SkySQL" />
            </a>
        </v-toolbar-title>

        <v-spacer />

        <slot name="right">
            <v-btn
                v-if="canUpgradeToPower"
                color="accent"
                depressed
                class="mr-3 text-caption font-weight-medium"
                height="21"
                small
                rounded
                @click="openTierUpgradeDialog">
                {{ $t('tierUpgrade.upgrade') }}
            </v-btn>

            <UpgradeTierModal
                :show-dialog="showTierUpgrade"
                @cancel-upgrade="resetModal" />

            <ImpersonateUserModal
                :show-dialog="showImpersonate"
                @close="showImpersonate = false" />

            <NotificationsList />

            <v-menu
                transition="slide-y-transition"
                offset-y
                content-class="py-0">
                <template #activator="{ on }">
                    <v-btn
                        class="mr-0"
                        text
                        tile
                        v-on="on"
                        @click="isProfileOpened = !isProfileOpened">
                        <span class="ft-adrianna text-none d-md-inline">
                            <span v-if="impersonatedUser">
                                {{ user.firstName }}
                                ({{ impersonatedUserName }}
                                <v-tooltip bottom>
                                    <template #activator="{ on: tooltipHover }">
                                        <v-icon
                                            size="18"
                                            class="mb-1"
                                            v-on="tooltipHover">
                                            mdi-information
                                        </v-icon>
                                    </template>
                                    <span> {{ $t('impersonating') }} </span>
                                </v-tooltip>)
                            </span>
                            <span v-else>
                                {{ user.name }}
                            </span>
                            <v-icon
                                class="mr-0 ml-1"
                                left>
                                {{ "mdi-chevron-" + (isProfileOpened ? "up" : "down") }}
                            </v-icon>
                        </span>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        data-auto="user-tier">
                        <v-list-item-title>
                            <TierBadge
                                class="mr-4"
                                :tier-name="tier" />
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        data-auto="user-preference"
                        @click="$router.push('/user-profile')">
                        <v-list-item-title> {{ $t("profile") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        data-auto="user-billing"
                        @click="$router.push('/billing')">
                        <v-list-item-title> {{ $t("billing") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="canImpersonate"
                        data-auto="user-impersonate"
                        @click="showImpersonate = true">
                        <v-list-item-title> {{ $t("impersonate") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="impersonatedUser"
                        data-auto="user-exit-impersonate"
                        @click="exitImpersonate()">
                        <v-list-item-title> {{ $t("exitImpersonate") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        data-auto="logout-user"
                        @click="doLogout()">
                        <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </slot>
    </v-app-bar>
</template>

<script>
    import { mapActions } from 'vuex'
    import NotificationsList from 'components/alerts/NotificationsList.vue'
    import TierBadge from 'components/service/TierBadge.vue'
    import UpgradeTierModal from 'components/common/UpgradeTierModal.vue'
    import ImpersonateUserModal from 'components/impersonate/ImpersonateUserModal.vue'
    import { isFoundationTier } from 'utils/organization.js'

    export default {
        name: 'Header',
        components: {
            NotificationsList,
            TierBadge,
            UpgradeTierModal,
            ImpersonateUserModal,
        },
        props: {
            tier: {
                type: String,
                default: null,
            },
            user: {
                type: Object,
                default: null,
            },
            impersonatedUser: {
                type: Object,
                default: null,
            },
        },
        data () {
            return {
                isProfileOpened: false,
                showTierUpgrade: false,
                showImpersonate: false,
            }
        },
        computed: {
            previousRelease(){
                return this.$config.OLD_PORTAL_URL
            },
            canUpgradeToPower() {
                return isFoundationTier(this.tier)
            },
            canImpersonate() {
                return this.user?.isEmployee && !this.impersonatedUser
            },
            impersonatedUserName() {
                const { firstName, lastName, } = this.impersonatedUser
                return firstName + ' ' + lastName
            },
        },
        methods: {
            ...mapActions(['setupImpersonation']),
            doLogout () {
                this.$emit('logoutUser')
            },
            openTierUpgradeDialog() {
                if (!this.canUpgradeToPower) {
                    return
                }
                this.showTierUpgrade = true
            },
            resetModal() {
                this.showTierUpgrade = false
            },
            async exitImpersonate() {
                await this.setupImpersonation(null)
                window.location.reload()
            },
        },
    }
</script>
