export default Object.freeze({
    COPILOT_VIDEO_URL: 'https://www.youtube.com/watch?v=RguT7vo4u9A',
    COPILOT_VIDEO_EMBED: 'https://www.youtube.com/embed/RguT7vo4u9A?si=ZCykPy-xpOT6AJJE',
    SYSTEM_AGENTS: {
        'ama': {
            id: '89256c79-bdfe-435a-8729-4d1e9a9ebca2',
            starterQuestions: [
                { text: 'Get Connected - From a Java App?', prompt: 'How do I connect from my Java app to SkySQL? SSL is ON. Show me an example.', },
                { text: 'Get Connected - From a Node.js app', prompt: 'How do I connect from my Node.js app to SkySQL? SSL is ON. Show me an example.', },
                { text: 'Get Connected - Using DBeaver tool', prompt: 'Show me the steps to connect to SkySQL using DBeaver tool.', },
                { text: 'Load data - Create and load using Synthetic Data', prompt: 'Need a SQL script to create a sample test database testdb with three tables: Users, Orders, and Products, along with foreign key relationships between them. After creating the schema and tables, we\'ll populate them with sample data.', },
                { text: 'Load data - Create table and load CSV', prompt: 'Need to load data from a CSV file. I need all the instructions to load the CSV into SkySQL using the MariaDB CLI. Steps : (1) create the database testdb if it doesn\'t exist (2) Create the table from the CSV data below. Infer the correct data types (3) Load the local CSV file into this table. My CSV : user_id,username,email,created_at 1,john_doe,john@example.com,2024-10-01 10:15:00 2,jane_smith,jane@example.com,2024-10-02 11:30:00', },
                { text: 'Migration - Live migration to SkySQL?', prompt: 'I need to migrate my on-prem MySQL database to SkySQL. Show the precise commands for these steps (1) get logical dump of my source DB (2) import this dump into SkySQL. (3) setup replication between this external DB and SkySQL Database using SkySQL procedures.', },
                { text: 'Generate SQL - generate Schema from sample JSON', prompt: 'I have a JSON Object. I want to manage this in SkySQL DB as Tables. Keep my data flat in tables. { \"id\": \"0001\", \"type\": \"donut\", \"name\": \"Cake\", \"ppu\": 0.55, \"batters\": { \"batter\": [ { \"id\": \"1001\", \"type\": \"Regular\" }, { \"id\": \"1002\", \"type\": \"Chocolate\" }, { \"id\": \"1003\", \"type\": \"Blueberry\" }, { \"id\": \"1004\", \"type\": \"Devil\'s Food\" } ] }, \"topping\": [ { \"id\": \"5001\", \"type\": \"None\" }, { \"id\": \"5002\", \"type\": \"Glazed\" }, { \"id\": \"5005\", \"type\": \"Sugar\" }, { \"id\": \"5007\", \"type\": \"Powdered Sugar\" }, { \"id\": \"5006\", \"type\": \"Chocolate with Sprinkles\" }, { \"id\": \"5003\", \"type\": \"Chocolate\" }, { \"id\": \"5004\", \"type\": \"Maple\" } ] }', },
                { text: 'DR - setup a remote replica for DR', prompt: 'Show me the precise steps to setup SkySQL global replication. I want to setup for DR.', },
                { text: 'Backup - schedule a backup every 6 hours?', prompt: 'I want to schedule full backups for my SkySQL db once every 6 hours. Show me the precise curl command I can use.', },
                { text: 'Tune, Learn MariaDB - how I can tune Query performance?', prompt: 'Show and describe the most important variables I can use to tune MariaDB query performance. Which ones are configurable in SkySQL?', }
            ],
        },
        'data': {
            id: 'afe84b79-f29f-4f9d-8aa3-a7363df3628c',
            starterQuestions: [
                { text: 'Summarize my db schema.', prompt: 'Summarize my db schema.', },
                { text: 'Sample questions you can help answer.', prompt: 'Provide a list of sample questions you can help answer.', }
            ],
        },
        'dba': {
            id: 'ce4f719f-c537-489a-b5c3-08e111f5736a',
            starterQuestions: [
                { text: 'Produce a report on the health of my server.', prompt: 'Provide a report on the health of my server. Include the status of replication and any errors.', },
                { text: 'Find the queries taking the most time?', prompt: 'Show me the top few slow queries. Don\'t explain or analyze?', },
                { text: 'Analyze the slowest query', prompt: 'Analyze the slowest query and provide details on where the time is spent.', },
                { text: 'Analyze the slowest query and provide specific optimization suggestions.', prompt: 'Analyze the slowest query and provide specific optimization suggestions.', },
                { text: 'Fetch all currently running queries.', prompt: 'Show me all currently running queries.', },
                { text: 'Find which users have been the most active?', prompt: 'Which users have been the most active?', },
                { text: 'Analyze the state of my InnoDB storage engine. Any insights?', prompt: 'Analyze the state of my InnoDB storage engine. Any insights?', },
                { text: 'How many threads are active and what is the maximum allowed?', prompt: 'How many threads are active and what is the maximum allowed?', },
                { text: 'Fetch the status of my replication?', prompt: 'What is the status of my replication?', }
            ],
        },
    },
})
