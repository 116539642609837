<template>
    <div>
        <TableActions
            :loading="loading"
            :selected-items="selectedItems"
            :title="$t('billings.savedPayments')"
            @add-new="addNewPayment" />
        <v-data-table
            :headers="headers"
            fixed-header
            :items="paymentMethods"
            :loading="loading"
            hide-default-footer
            disable-pagination
            item-key="paymentMethod"
            class="data-table-full">
            <template #no-data>
                <span>{{ $t('info.noSavedPaymentMethods') }} -</span>
                <span
                    class="ml-1 color pointer text-links"
                    @click="addNewPayment">{{ $t('addNew') }}</span>
            </template>
            <template #item="{ item: paymentMethod }">
                <tr>
                    <td>
                        {{ paymentMethod.billingDetails?.name || '' }}
                    </td>
                    <td>
                        {{ $help.maskedCCNumber(paymentMethod.card) || '' }}
                    </td>
                    <td class="text-uppercase">
                        {{ paymentMethod.card?.cardType || '' }}
                    </td>
                    <td>
                        {{ $help.capitalize(paymentMethod.type, '_') || '' }}
                    </td>
                    <td>
                        {{ paymentMethod.card?.expirationMonth || '' }}/{{ paymentMethod.card?.expirationYear || '' }}
                    </td>
                    <td>
                        <div
                            v-if="paymentMethod.defaultPaymentMethod"
                            class="default-method-icon pl-6">
                            <v-icon
                                size="24"
                                class="mt-2">
                                $vuetify.icons.statusOk
                            </v-icon>
                        </div>
                    </td>
                    <td>
                        <v-menu
                            transition="slide-y-transition"
                            offset-y>
                            <template #activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon>
                                        mdi-dots-horizontal
                                    </v-icon>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="editPayment(paymentMethod)">
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    class="error--text"
                                    @click="openDelModal(paymentMethod)">
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <AlertDeleteModal
            ref="delModal"
            :title="$t('billings.deletePaymentConfirmationTitle')"
            :delete-msg="$t('billings.deletePaymentConfirmationMessage')"
            :item-key-name="''"
            :on-delete="onDeletePayment"
            :on-cancel="onDeleteClose" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import TableActions from 'components/alerts/TableActions.vue'
    import AlertDeleteModal from 'components/alerts/AlertDeleteModal.vue'

    export default {
        name: 'PaymentTable',
        components: {
            TableActions,
            AlertDeleteModal,
        },
        props: {
            paymentMethods: { type: Array, required: true, },
            loading: { type: Boolean, default: false, },
        },
        data () {
            return {
                headers: [
                    { text: this.$t('billings.name'), value: 'name',sortable: false,},
                    { text: this.$t('billings.cardNumber'), value: 'cardNumber', sortable: false, },
                    { text: this.$t('billings.cardType'), value: 'cardType', sortable: false, },
                    { text: this.$t('type'), value: 'type', sortable: false, },
                    { text: this.$t('Expiry'), value: 'expiration', sortable: false, },
                    { text: this.$t('billings.isDefault'), value: 'isDefault', sortable: false, },
                    { text: '', value: 'menu', width: '30px', sortable: false, }
                ],
                selectedItems: [],
                paymentForDelete: null,
            }
        },
        computed: {
            ...mapGetters([
                'deletePaymentSuccess'
            ]),
        },
        methods: {
            ...mapActions([
                'deletePayment'
            ]),
            editPayment(paymentMethod) {
                this.$emit('edit-payment', paymentMethod)
            },
            onDeleteClose() {
                this.paymentForDelete = null
                this.$refs.delModal.isDialogOpen = false
            },
            async onDeletePayment() {
                await this.deletePayment(this.paymentForDelete?.paymentMethodId)
                this.$refs.delModal.isDialogOpen = false
                if (this.deletePaymentSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.paymentDeletedSuccessfully'), type: '#03a128', })
                    this.$emit('delete-payment')
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.deletePayment'), type: this.$config.messageType.ERROR, })
                }
            },
            openDelModal (paymentMethod) {
                this.paymentForDelete = paymentMethod
                this.$refs.delModal.open(this.selectedItems)
            },
            addNewPayment () {
                this.$emit('add-payment')
            },
        },
    }
</script>
