<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid $cornsilk;
}

.per-min-cost {
    color: $grayed-out
}
</style>
<template>
    <div v-if="hasNode">
        <v-row
            :data-auto="`nodeItem-${childIndex}`"
            class="border-bottom pt-2">
            <v-col
                class="pl-10 font-weight-bold pointer"
                cols="5"
                @click="toggleTab">
                <v-icon
                    v-if="hasChildren"
                    :class="['mr-2', { 'rotate--right': !isExpanded }]"
                    size="13">
                    $expand
                </v-icon>
                <span
                    :data-auto="`childItem-title-${childIndex}`"
                    :class="['node-title', { 'pl-6': !hasChildren }]">{{
                        node.title
                    }}</span>
            </v-col>
            <v-col cols="4" />
            <v-col
                :data-auto="`childItem-cost-${childIndex}`"
                class="font-weight-bold text-right pr-0">
                {{ $t('currency', [node.cost.toFixed(2)]) }}
            </v-col>
            <v-col cols="2" />
        </v-row>
        <template v-if="isExpanded">
            <BillingPanelLeafNodes
                v-if="hasChildren"
                :node="node" />
        </template>
    </div>
</template>
<script>
    import BillingPanelLeafNodes from 'components/billing/BillingPanelLeafNodes.vue'

    const BillingPanelChildNodes = {
        name: 'BillingPanelChildNodes',
        components: {
            BillingPanelLeafNodes,
        },
        props: {
            node: {
                type: Object,
                required: true,
            },
            childIndex: {
                type: Number,
                required: true,
                default: 0,
            },
        },
        data() {
            return {
                isExpanded: false,
            }
        },
        computed: {
            hasNode() {
                return this.node && this.node?.title
            },
            hasChildren() {
                const { children, } = this.node
                return children && children.length
            },
        },
        methods: {
            toggleTab() {
                this.isExpanded = !this.isExpanded
            },
        },
    }

    export default BillingPanelChildNodes
</script>
