<style lang="scss" scoped>
    .region-heading {
        color: #a6a4a6;
    }
    .region-icons {
        height: 2.5rem;
    }
    .region-wrapper {
        position: relative;
        padding: 5px;
        border: 2px solid transparent;
    }
    .region-wrapper.highlight-instance-row {
        border-radius: 10px;
    }
    .unavailable-region{
        color: $disabled-region;
    }
</style>

<template>
    <v-expansion-panel
        id="cloudProviderPanel"
        :disabled="isDisabled"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="getLocaleFor('cloudProvider')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <div>{{ $t('launchService.cloudProviderInfo') }}</div>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <v-row class="pt-8">
                <CloudServiceProvider
                    :is-selected="providerType === 'aws'"
                    :icon="`$vuetify.icons.aws`"
                    :icon-size="60"
                    type="aws"
                    border="left"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    @handleCloudProviderSelection="handleCloudProviderSelection" />
                <CloudServiceProvider
                    :is-selected="providerType === 'gcp'"
                    :icon="`$vuetify.icons.googleCloud`"
                    :icon-size="180"
                    type="gcp"
                    :border="$unleash.isFeatureEnabled('enable-portal-azure') ? '' : 'right'"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    @handleCloudProviderSelection="handleCloudProviderSelection" />
                <CloudServiceProvider
                    v-if="$unleash.isFeatureEnabled('enable-portal-azure')"
                    :is-selected="providerType === 'azure'"
                    :icon="`$vuetify.icons.microsoftAzure`"
                    :icon-size="120"
                    type="azure"
                    border="right"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    @handleCloudProviderSelection="handleCloudProviderSelection" />
            </v-row>
            <v-row v-if="isFreeTrialActive">
                <v-alert
                    class="mb-0 mt-2"
                    style="width: 100%;"
                    type="info"
                    color="primary"
                    dense
                    outlined>
                    {{ $t('launchService.freeTrialRegionsAvailable') }}
                </v-alert>
            </v-row>
            <v-row
                class="region-heading"
                :data-auto="dataAutoPrefix('content-regions')">
                <v-col class="pb-0">
                    Americas
                </v-col>
                <v-col class="pb-0">
                    EMEA
                </v-col>
                <v-col class="pb-0">
                    APAC
                </v-col>
            </v-row>
            <v-row v-if="regions.length">
                <RegionList
                    :location-data="americaLocations"
                    region="america"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :current-location="location"
                    @handleRegionSelection="handleRegionSelection" />
                <RegionList
                    :location-data="emeaLocations"
                    region="emea"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :current-location="location"
                    @handleRegionSelection="handleRegionSelection" />

                <RegionList
                    :location-data="apacLocations"
                    region="apac"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :current-location="location"
                    @handleRegionSelection="handleRegionSelection" />
            </v-row>
            <div
                v-if="showClusterMessage"
                class="mt-4 font-italic">
                <v-icon
                    size="20"
                    color="primary">
                    mdi-star
                </v-icon> {{ $t('cluster-exists') }}
            </div>
            <div
                v-if="showMaintenanceMessage && region.maintenanceWindow"
                class="mt-4">
                {{ $t('launchService.maintenanceFoundation') }}
                <i18n
                    path="launchService.maintenanceWindow"
                    tag="p">
                    <template #region>
                        <span class="font-weight-medium">{{ region.name }}</span>
                    </template>
                    <template #window>
                        <span>{{ region.maintenanceWindow }}</span>
                    </template>
                </i18n>
            </div>
            <CloudZoneSelection
                v-if="isSingleZoneEnabled"
                :key="region.name"
                :default-zone="currentZone"
                :zones="zones"
                :is-single-node="isSingleNode"
                @on-single-zone-opted="onSingleZoneOpted"
                @on-zone-select="onProviderZoneSelect" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import i18n from 'plugins/i18n'
    import { mapGetters, mapActions } from 'vuex'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader'
    import CloudServiceProvider from 'components/launchService/CloudServiceProvider.vue'
    import RegionList from 'components/launchService/RegionList.vue'
    import CloudZoneSelection from 'components/launchService/CloudZoneSelection.vue'
    import { getFlagIconForRegion } from 'utils/countries'
    import { isStandAloneTopology, isServerlessTopology, isProviderAzure } from 'utils/service'

    export default {
        name: 'CloudProviderSelection',
        components: {
            AccordianPanelHeader,
            CloudServiceProvider,
            RegionList,
            CloudZoneSelection,
        },
        props: {
            getLocaleFor: {
                type: Function,
                default: (txt) => txt,
            },
            topology: { type: String, default: '', },
            location: { type: String, default: '', },
            providerType: { type: String, default: '', },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            currentTier: {
                type: String,
                required: true,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return{
                region: '',
                currentZone: '',
            }
        },
        computed:{
            ...mapGetters(['regions', 'availabilityZones', 'isFreeTrialActive']),
            americaLocations(){
                return this.filterRegionsForRegions(this.$config.regions.america)
            },
            emeaLocations(){
                return this.filterRegionsForRegions(this.$config.regions.emea)
            },
            apacLocations(){
                return this.filterRegionsForRegions(this.$config.regions.apac)
            },
            currentProviderRegions() {
                return this.regions
                    .filter(region => region.provider === this.providerType)
                    .map(this.mapFlagForRegion)
            },
            showClusterMessage(){
                return this.currentTier === this.$config.POWER_TIER
            },
            showMaintenanceMessage(){
                return this.currentTier === this.$config.FOUNDATION_TIER
            },
            zones(){
                return this.availabilityZones.filter(zone=> zone.region_name === this.region.name)
            },
            isSingleNode(){
                return isStandAloneTopology(this.topology)
            },
            isSingleZoneEnabled() {
                if (isServerlessTopology(this.topology) || isProviderAzure(this.providerType)) {
                    return false
                }
                return this.$unleash.isFeatureEnabled('enable-portal-single-zone') && this.region?.name
            },
        },
        watch: {
            currentProviderRegions() {
                this.selectDefaultRegion()
            },
            async topology(newVal, oldVal) {
                if (oldVal && newVal) {
                    await this.getRegions(newVal)
                    this.selectDefaultRegion()
                }
            },
        },
        methods: {
            ...mapActions(['getRegions']),
            async handleCloudProviderSelection (type) {
                this.$emit('onCloudProviderSelect', type)
            },
            handleRegionSelection (region) {
                this.region = region
                this.$emit('onRegionSelect', region)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(
                    `cloudProviderPanel${string && '-' + string}`
                )
            },
            filterRegionsForRegions (regionName) {
                // This function is basically doing a filter on the regions data
                // based on the region name provided.
                // Returns regions data that belong to passed region name.
                return (
                    (this.currentProviderRegions &&
                        this.currentProviderRegions.filter(
                            (prvoiderRegion) => prvoiderRegion.region === regionName
                        )) ||
                    []
                )
            },
            mapFlagForRegion (providerRegion) {
                let icon = `$vuetify.icons.${getFlagIconForRegion(providerRegion.name)}`
                return {
                    ...providerRegion,
                    icon,
                    label: i18n.t('regions.' + providerRegion.name),
                    isDisabled: !providerRegion.isAvailable,
                }
            },
            onProviderZoneSelect(currentZone) {
                this.currentZone = currentZone
                this.$emit('on-zone-select', currentZone)
            },
            onSingleZoneOpted(isOpted){
                if(!isOpted) this.currentZone = ''
                this.$emit('on-zone-select', '')
            },
            selectDefaultRegion() {
                const { DEFAULT_REGIONS_LIST, } = this.$config
                const defaultRegions = DEFAULT_REGIONS_LIST[this.providerType] || []
                const availableRegions = this.currentProviderRegions.filter(region => region.isAvailable && defaultRegions.includes(region.name))
                const randomIndex = Math.floor(Math.random() * availableRegions.length)
                const defaultRegion = availableRegions[randomIndex] || currentProviderRegions[0]
                if (defaultRegion) {
                    this.handleRegionSelection(defaultRegion)
                }
            },
        },
        async created () {
            await this.getRegions(this.topology)
            // Preselect options
            this.selectDefaultRegion()
        },
    }
</script>
