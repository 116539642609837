<style lang="scss">
    .main-nav {
        z-index: -1;

        .v-navigation-drawer__border {
            background-color: transparent !important;
            border-right: 1px solid $cornsilk;
        }
        .navitem {
            transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important; // same easing as the nav drawer open/close animation
            height: 50px;
            &:hover {
                background: rgba(0, 0, 0, 0.14) !important;
            }
            .v-list-item__icon {
                min-width: 50px;
                height: 100%;
                margin: 0;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .v-navigation-drawer--mini-variant .navitem .v-list-item__content {
        display: flex;
        opacity: 0;
    }
</style>

<style lang="scss" scoped>
    @import "styles/constants";

    .main-nav {
        z-index: 7;
        background: #ffffff;

        .v-list {
            padding: 0;
            background: transparent;

            .navitem {
                position: relative;
                padding: 0 8px;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 10%;
                    top: auto;
                    width: 3px;
                    height: 80%;
                    max-height: 42px;
                }
            }
            .v-list-item__title {
                height: 45px;
            }
        }

        hr.v-divider {
            border-color: #556072;
            display: block !important;
        }
    }
</style>

<template>
    <div>
        <v-navigation-drawer
            class="main-nav"
            width="240"
            fixed
            app
            permanent
            @mouseout.native="isMini = true && !isExpanded">
            <v-list class="top-nav">
                <div
                    v-for="item in menu"
                    :key="item.title"
                    :class="item.className">
                    <SideNavItem
                        :item="item"
                        :matched-routes="matchedRoutes"
                        @onClick="navigate" />
                </div>
            </v-list>
            <template #append>
                <v-list class="bottom-nav">
                    <div
                        v-for="item in bottomNav"
                        :key="item.title"
                        :class="item.className">
                        <SideNavItem
                            :item="item"
                            @onClick="navigate" />
                    </div>
                </v-list>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import SideNavItem from 'components/SideNavItem.vue'

    export default {
        name: 'Navigation',
        components: { SideNavItem, },
        data () {
            return {
                isMini: true,
                subNavigation: null,
                searchTags: '',
                items: this.$config.navigation,
            }
        },
        computed: {
            ...mapGetters([
                'user'
            ]),
            menu () {
                return this.items.filter((menu) => {
                    let route = this.$router.options.routes.find(
                        (r) => r.name === menu.route
                    )
                    let isVisible = true
                    if (
                        route &&
                        route.meta?.isAdminOnly &&
                        this.$store.state?.user?.role !== 'admin'
                    ) {
                        isVisible = false
                    }

                    if (menu.isBottomNav) {
                        isVisible = false
                    }
                    return isVisible
                })
            },
            bottomNav () {
                return this.items.filter((menu) => menu.isBottomNav)
            },
            matchedRoutes () {
                const matched = []
                if (this.$route.matched) {
                    this.$route.matched.forEach((route) =>
                        matched.push(route.name)
                    )
                }
                return matched
            },
            isExpanded () {
                return !!this.subNavigation
            },
        },
        methods: {
            navigate ({ route, url, title, params, }) {
                if (url) {
                    window.open(url)
                }
                if (route) {
                    this.$router.push({ name: route, params,  })
                }
                if (title?.toLowerCase() === 'skycopilot') {
                    this.$sentry.captureEvent({message: 'AI Copilot menu is clicked', user: {id: this.user.id, username: this.user.name, }, level: 'info',})
                }
            },
        },
    }
</script>
