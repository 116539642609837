<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="doClose"
        :on-close="doClose"
        :min-width="860"
        :max-width="860">
        <template #title>
            <div class="font-weight-light pb-1 text-h4">
                {{ $t('copilot.agentInfoTitle') }}
            </div>
            <div class="font-weight-light pb-6 text-body-1">
                {{ $t('copilot.agentSelected') }}: {{ selectedAgent.name }}
            </div>
        </template>
        <template #body>
            <v-col cols="12">
                <div v-if="isInfoData">
                    <p>
                        Your questions are translated into one or more SQL queries using only the table schema information.
                        We <strong>strongly recommend going through the steps outlined in the <a
                            href="https://skysql.notion.site/Sky-Copilot-Prompt-Engg-Guide-fc2971f5162b421588c9750e7b147d5e?pvs=73"
                            target="_blank"
                            rel="noopener noreferrer">Prompt Engg Guide</a>
                            to improve results</strong>.
                    </p>
                    <p>To use, follow these steps:</p>
                    <ul>
                        <li>
                            <em>Step (1)</em>: Connect to a DB service. The demo server has schemas like the Microsoft <a
                                href="https://support.microsoft.com/en-us/office/northwind-database-diagram-cd422d47-e4e3-4819-8100-cdae6aaa0857"
                                target="_blank"
                                rel="noopener noreferrer">Northwind DB</a>.
                        </li>
                        <li><em>Step (2)</em>: Select the DB schema to operate on. Currently we only support one DB at a time.</li>
                        <li><em>Step (3)</em>: Select the Tables for more selective context. Recommended if your DB has a lot of tables.</li>
                    </ul>
                    <p class="mt-2">
                        NOTE - we lazily index (i.e. add to Vector DB) all the table metadata, descriptions when you ask your first question, after selections.
                        The time taken to index is proportional on the number of tables selected.
                    </p>
                </div>
                <div v-else-if="isInfoDBA">
                    <h3 class="mb-2">
                        Important information:
                    </h3>
                    <div>
                        The simplest way to get started is by using our Demo DB Server. It features a standalone MariaDB server preloaded with sample data and includes logged slow queries for testing.<br /><br />
                        You can begin by exploring some of the sample questions provided below. Alternatively, connect to any MariaDB server running on SkySQL or another platform to experiment with your own workloads.
                    </div>

                    <h3 class="mt-6 mb-2">
                        Note the following:
                    </h3>
                    <ul class="mb-4">
                        <li>If you are using your own database, we recommend starting with Development/Test DBs first.</li>
                        <li>The DB user used by DBA Copilot needs the privileges as noted below.</li>
                    </ul>
                    <div>
                        Create the following user and grant the privileges as shown below. Please note, Copilot doesn’t require write privileges to your schema. Remember to replace “some_compliant_password” with your own password.
                    </div>
                    <pre class="mt-2 language-sql">
                        <code class="language-sql">
    CREATE USER IF NOT EXISTS 'skyai'@'%' IDENTIFIED BY 'some_compliant_password';
    GRANT SELECT, PROCESS, SHOW VIEW, SHOW DATABASES ON *.* TO `skyai`@`%` ;
    GRANT CREATE, DROP, CREATE VIEW ON `sky_sys_catalog`.* TO `skyai`@`%` ;
                        </code>
                    </pre>

                    <h3 class="mt-6 mb-2">
                        SlowQuery analysis
                    </h3>
                    <ul>
                        <li>To analyze slow queries, you need to turn on 'Slow query' logging. The slow_log overhead is proportional to the amount of queries logged. It is recommended you start with a high slow_query_time, implement a log_slow_rate_limit, and disable logging when not in use.</li>
                        <li>
                            If using SkySQL, go to <a
                                href="https://app.skysql.com/settings/configuration-manager"
                                target="_blank">Config Manager</a> to see all the current configuration templates. If you are using the default config("SkySQL Default - Enterprise Server..."), click the 'Create New' button, and change the following settings:
                        </li>
                    </ul>
                    <ol start="2">
                        <li>Change 'slow_query_log' to ON.</li>
                        <li>Change 'log_output' to TABLE (defaults to FILE).</li>
                        <li>
                            Adjust the 'long_query_time' if required (Defaults to 10 secs).<br />
                            Caution: If 'long_query_time' is set too low you could substantially increase the load. You can check the global status variable <a
                                href="https://mariadb.com/kb/en/server-status-variables/#slow_queries"
                                target="_blank"
                                rel="noopener noreferrer">slow_queries</a> to tune the long_query_time.
                        </li>
                    </ol>

                    <h3 class="mt-6 mb-2">
                        Performance Schema
                    </h3>
                    <div>
                        It is also useful to turn ON 'Performance_schema' (Note that this option will restart your DB service and does introduce between 1-10% overhead so implementation should be tested/tuned for best practice).
                    </div>
                </div>
            </v-col>
        </template>
        <template #actions="{ save }">
            <v-switch
                v-model="markAsRead"
                :label="$t('dontShowAgain')"
                color="primary"
                class="ma-0 pa-0"
                hide-details
                small />
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                @click="save">
                <span class="text-none px-4"> {{ $t('ok') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import Prism from 'prismjs'
    import { mapGetters } from 'vuex'
    import { isCopilotAgent } from 'services/copilot.js'

    export default {
        name: 'AgentInfoModal',
        data() {
            return {
                isDialogOpen: false,
                markAsRead: false,
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent'
            ]),
            isInfoData() {
                return isCopilotAgent('data', this.selectedAgent?.id)
            },
            isInfoDBA() {
                return isCopilotAgent('dba', this.selectedAgent?.id)
            },
        },
        methods: {
            open() {
                this.markAsRead = localStorage.getItem(`copilot-agent-${this.selectedAgent.id}`) === 'true'
                this.isDialogOpen = true
                setTimeout(() => {
                    Prism.highlightAll()
                }, 0)
            },
            doClose() {
                if (this.markAsRead) {
                    localStorage.setItem(`copilot-agent-${this.selectedAgent.id}`, 'true')
                } else {
                    localStorage.removeItem(`copilot-agent-${this.selectedAgent.id}`)
                }
                this.markAsRead = false
                this.isDialogOpen = false
            },
        },
    }
</script>
