<style lang="scss" scoped>
.notice {
    background: $estimate-calculations;
}
</style>
<template>
    <v-card
        outlined
        class="details-card mb-2">
        <v-card-title class="details-title text-body-2 d-flex justify-space-between text-uppercase">
            {{ $t('serviceDetailsTab.serviceDetails') }}
        </v-card-title>
        <v-card-text>
            <v-row
                data-auto="service-details.architecture"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    ID
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.id }}
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.architecture"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('architecture') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.architecture }}
                </v-col>
            </v-row>
            <v-row
                v-if="!isServerless"
                data-auto="service-details.instance-size"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('size') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.size }}
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.version"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('version') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.version }}
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.created-date"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('created') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ $moment((service.assigned_on || service.created_on) * 1000).format('HH:mm:ss YYYY-MM-DD') }}
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.storage-size"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('serviceDetailsTab.storage') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ `${service.storage_volume.size} ${$t('launchService.gb')}` }}
                </v-col>
            </v-row>
            <v-row
                v-if="!isServerless"
                data-auto="service-details.nodes-or-replicas"
                :class="[commonRowClasses, 'text-capitalize']">
                <v-col class="px-3 py-0">
                    {{ labelForNodes }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ valueForNodes }}
                </v-col>
            </v-row>
            <v-row
                v-if="!$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                data-auto="service-details.read-only-port"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('readOnlyPort') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ servicePorts.readonly || $t('notApplicable') }}
                </v-col>
            </v-row>
            <v-row
                v-if="!$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                data-auto="service-details.read-write-port"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('readWritePort') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ servicePorts.readwrite || $t('notApplicable') }}
                </v-col>
            </v-row>
            <v-row
                v-if="!isServerless"
                data-auto="service-details.nosql-port"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('noSQLPort') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ servicePorts.nosql || $t('notApplicable') }}
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.outbound-ip"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('serviceDetailsTab.outboundIP') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ outboundIP }}
                </v-col>
            </v-row>
            <v-row
                data-auto="service-details.outbound-ip"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    SSL/TLS
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.ssl_enabled ? $t('enabled') : $t('disabled') }}
                </v-col>
            </v-row>
            <v-row
                v-if="!isServerless"
                data-auto="service-details.auto-scale-nodes"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('serviceDetailsTab.autoScaleNodes') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ autoScaleNodesValue }}
                </v-col>
            </v-row>
            <v-row
                v-if="!isServerless"
                data-auto="service-details.auto-scale-storage"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('serviceDetailsTab.autoScaleStorage') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ autoScaleStorageValue }}
                </v-col>
            </v-row>
            <v-row
                v-if="service.maxscale_nodes > 1"
                data-auto="service-details.maxscale-redundancy"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('maxScaleRedundancy') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ maxScaleRedundancyValue }}
                </v-col>
            </v-row>
            <v-row
                v-if="service.availability_zone"
                data-auto="service-details.availability-zone"
                :class="commonRowClasses">
                <v-col class="px-3 py-0">
                    {{ $t('availabilityZone') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.availability_zone }}
                </v-col>
            </v-row>
            <v-row
                v-if="!$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                data-auto="service-details.fqdn"
                :class="[commonRowClasses, 'py-5']">
                <v-col
                    class="px-3 py-0 font-weight-bold"
                    cols="10">
                    {{ $t('serviceDetailsTab.fqdn') }}
                </v-col>
                <v-col class="px-3 py-0">
                    {{ service.fqdn }}
                </v-col>
            </v-row>
            <v-row
                v-if="!$unleash.isFeatureEnabled('enable-portal-dual-endpoints') && hasPrivateConnect"
                data-auto="service-details.secure-endpoint"
                :class="[commonRowClasses, 'pb-4']">
                <v-col
                    class="px-3 py-0 font-weight-bold"
                    cols="12">
                    {{ $t('serviceDetailsTab.secureEndpoint') }}
                </v-col>
                <v-col
                    class="px-3 py-0"
                    cols="10">
                    {{ privateConnectEndpoint }}
                </v-col>
                <v-col class="pt-0 mt-0">
                    <v-btn
                        class="copy-btn"
                        color="primary"
                        icon
                        small
                        @click="copyToClipboard(privateConnectEndpoint)">
                        <v-icon>$vuetify.icons.copy</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                v-if="!$unleash.isFeatureEnabled('enable-portal-dual-endpoints') && hasPrivateConnect && !havePrivateConnectProjects"
                class="pt-0 mt-0">
                <v-col>
                    <i18n
                        class="notice rounded pa-4"
                        path="privateConnect.serviceDetailSetupPrivateConnectMessage"
                        tag="div">
                        <span
                            class="font-weight-bold"
                            place="important">{{ $t('privateConnect.important') }}</span>
                        <a
                            place="finishSetup"
                            @click="setupPrivateConnect">{{ $t('privateConnect.finishSettingUp', {
                                connectionName: privateConnectType
                            }) }}</a>
                    </i18n>
                </v-col>
            </v-row>
            <v-row
                v-if="!$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                data-auto="service-details.db-config"
                :class="[commonRowClasses, 'pb-4']">
                <v-col
                    class="px-3 py-0 font-weight-bold"
                    cols="12">
                    {{ $t('serviceDetailsTab.databaseConfig') }}
                </v-col>
                <v-col class="px-3 py-0">
                    <router-link
                        v-if="configValue"
                        :to="`/settings/configuration-manager/${configValue}`">
                        {{ configValue }}
                    </router-link>
                    <span v-else>
                        {{ $t('notApplicable') }}
                    </span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import i18n from 'plugins/i18n'
    import { getMaxScaleRedundancyLabel } from 'utils/billing'
    import {
        isServerlessTopology,
        doPrivateConnectHaveProjects,
        getPrivateConnectServiceEndpoint,
        isPrivateConnectOpted,
        privateConnectLabel
    } from 'utils/service'

    export default {
        name: 'Details',
        props: {
            service: {
                type: Object,
                required: true,
            },
            servicePolicies: {
                type: Array,
                required: true,
            },
            allTopologies: {
                type: Array,
                required: true,
            },
            configurationList: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                configValue: '',
            }
        },
        computed: {
            ...mapGetters(['getServiceEndpointPorts']),
            hasNodes() {
                return this.$help.hasNodesInTopology(this.service.topology)
            },
            labelForNodes() {
                const nodes = this.valueForNodes
                return this.hasNodes ? i18n.tc('launchService.nodes', nodes) : i18n.tc('launchService.replicas', nodes)
            },
            valueForNodes() {
                return this.hasNodes ? this.service.nodes : this.service.nodes - 1
            },
            outboundIP() {
                return this.service.outbound_ips ? this.service.outbound_ips[0] : i18n.t('notApplicable')
            },
            autoScaleNodesValue() {
                const { VERTICAL_SCALE_GROUP, HORIZONTAL_SCALE_GROUP, } = this.$config
                const verticalPolicy = this.servicePolicies.find(policy => policy.service_id === this.service.id && policy.group === VERTICAL_SCALE_GROUP)
                const horizontalPolicy = this.servicePolicies.find(policy => policy.service_id === this.service.id && policy.group === HORIZONTAL_SCALE_GROUP)
                if (verticalPolicy) {
                    return i18n.t('serviceDetailsTab.autoUpDown', { size: verticalPolicy.params.max_node_size, })
                }
                else if (horizontalPolicy) {
                    return i18n.t('serviceDetailsTab.autoOutIn', { nodes: horizontalPolicy.params.max_nodes, })
                }
                return i18n.t('disabled')
            },
            autoScaleStorageValue() {
                const { STORAGE_SCALE_GROUP, } = this.$config
                const storagePolicy = this.servicePolicies.find(policy => policy.service_id === this.service.id && policy.group === STORAGE_SCALE_GROUP)
                if (storagePolicy) {
                    return i18n.t('serviceDetailsTab.autoStorage', { size: storagePolicy.params.max_storage_size_gbs, })
                }
                return i18n.t('disabled')
            },
            commonRowClasses() {
                return 'text-caption color text-navigation'
            },
            servicePorts() {
                const ports = {}
                const servicePorts = this.getServiceEndpointPorts(this.service.id, 'primary')
                servicePorts.forEach(({ purpose, port, }) => {
                    ports[purpose] = port
                })

                return ports
            },
            maxScaleRedundancyValue() {
                return getMaxScaleRedundancyLabel(this.service.maxscale_nodes, this.service.maxscale_size, true)
            },
            hasPrivateConnect() {
                return isPrivateConnectOpted(this.service.endpoints)
            },
            privateConnectEndpoint() {
                return getPrivateConnectServiceEndpoint(this.service.endpoints)
            },
            privateConnectType() {
                return privateConnectLabel(this.service.provider)
            },
            havePrivateConnectProjects() {
                return doPrivateConnectHaveProjects(this.service.endpoints)
            },
            isServerless() {
                return isServerlessTopology(this.service.topology)
            },
        },
        watch: {
            service() {
                this.setConfigValue()
            },
            allTopologies() {
                this.setConfigValue()
            },
        },
        methods: {
            ...mapActions(['copyToClipboard', 'openPrivateConnectModal']),
            setupPrivateConnect() {
                this.openPrivateConnectModal({ popupState: true, serviceId: this.service.id, })
            },
            // TODO: convert to computed property and remove watchers
            // Also get rid of the whole method and props as Configuration
            // is already moved to new widget as per DBAAS-13891
            setConfigValue() {
                if (!this.allTopologies.length) this.configValue = i18n.t('notApplicable')
                else if (this.service.config_id) {
                    this.configValue = this.configurationList.find(config => config.id === this.service.config_id).name
                }
                else {
                    const topologyId = this.allTopologies.find(topology => topology.name === this.service.topology).id
                    const foundConfig = this.configurationList.find(config => config.topology_id === topologyId)
                    this.configValue = foundConfig ? foundConfig.name : null
                }
            },
        },
    }
</script>
