import i18n from 'plugins/i18n'
import { CONFIG } from 'config/'
import {
    isStandAloneTopology,
    isPrimaryReplica,
    isStandaloneServerless
} from 'utils/service'

//TODO:: Move all the features into i18n and refactor as an utility
// https://jira.mariadb.org/browse/DBAAS-11707

function setTopologyAttributes(
    features = [],
    iconSize = 0,
    icon = '',
    title = '',
    description = '',
    serverType = '',
    isTechPreview = false
) {
    return {
        features,
        iconSize,
        icon,
        title,
        description,
        serverType,
        isTechPreview,
    }
}

export function getTopologyAttributes(topology) {
    if (isStandAloneTopology(topology)) {
        return setTopologyAttributes(
            [
                'SQL for relational & JSON',
                'MySQL/Oracle compatible',
                'Self-healing',
                'Fast, easy and affordable'
            ],
            50,
            '$vuetify.icons.singleNode',
            i18n.t('topology.es-single'),
            i18n.t('launchService.txn-single-node-desc'),
            i18n.t('serverType.es-single')
        )
    }
    if (isPrimaryReplica(topology)) {
        return setTopologyAttributes(
            [
                'All single node functionality',
                'Data proxy for load balancing and automatic zero-interruption failover',
                'Read scale (all replicas serve read requests)',
                'MongoDB compatible NoSQL API'
            ],
            80,
            '$vuetify.icons.replicated',
            i18n.t('topology.es-replica'),
            i18n.t('launchService.txn-replicated-desc'),
            i18n.t('serverType.es-replica')
        )
    }
    if (isStandaloneServerless(topology)) {
        return setTopologyAttributes(
            [
                '<strong>Forever free developer database</strong>: Get started with a free-tier database (up to 10 SCU-h per month).',
                '<strong>Pay only for what you use</strong>: Optimize costs by paying only for the resources consumed by your workload.',
                '<strong>Instant launch and resume</strong>: Deploy and pause your database instantly with zero delay.',
                '<strong>Fastest auto-scaling</strong>: Experience the industry\'s quickest auto-scaling to handle demand seamlessly.',
                '<strong>100% isolation</strong>: Enjoy dedicated resources with full isolation for secure and performant database operations.'
            ],
            50,
            '$vuetify.icons.singleNode',
            i18n.t('topology.serverless-standalone'),
            '',
            i18n.t('serverType.serverless-standalone')
        )
    }

    return {}
}

export function getServiceTypesAttributes(type) {
    let attributes = {}
    if (type === CONFIG.TRANSACTIONAL) {
        attributes = {
            icon: '$vuetify.icons.transactionsProcessing',
            title: i18n.t(`dbUseType.${type}`),
            isTechPreview: false,
        }
    } else {
        attributes = {
            icon: '$vuetify.icons.serverless',
            title: i18n.t(`dbUseType.${type}`),
            isTechPreview: true,
            isFreeTier: true,
        }
    }
    attributes.type = type
    return attributes
}

export function getTopologyName(topology = '', isShortName) {
    return `${i18n.t(`serverType.${topology}`)} ${
        isShortName ? '' : i18n.t(`topology.${topology}`)
    }`.trim()
}
