<template>
    <v-container
        fluid
        class="pt-7">
        <v-row>
            <v-col>
                <ContactCard
                    :loading="isFetchingContacts"
                    :contact="primaryContact" />
            </v-col>
            <v-col>
                <ContactCard
                    :loading="isFetchingContacts"
                    :contact="billingContact" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ContactCard from 'components/billing/contactManagement/ContactCard.vue'

    export default {
        name: 'ContactManagement',
        components: { ContactCard, },
        computed: {
            ...mapGetters([
                'contacts',
                'isFetchingContacts'
            ]),
            primaryContact() {
                return this.contacts.find(contact => contact.type.toLowerCase() === 'primary') || {
                    type: 'PRIMARY',
                }
            },
            billingContact() {
                return this.contacts.find(contact => contact.type.toLowerCase() === 'billing') || {
                    type: 'BILLING',
                }
            },
        },
        methods: {
            ...mapActions(['fetchContacts']),
        },
        async created() {
            await this.fetchContacts()
        },
    }
</script>
