<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;
}
.separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid $cornsilk;
    margin: 8px 0;
}
</style>

<template>
    <div>
        <v-row
            data-auto="billing-summary"
            class="billing-summary pa-2">
            <v-col cols="2">
                {{ $t('billings.usageDays') }}
                <div
                    data-auto="usage-days"
                    class="font-weight-bold text-subtitle-1">
                    {{ historicalBills?.usageDays }}
                </div>
            </v-col>
            <v-col cols="2">
                {{ $t('billings.computeCost') }}
                <div
                    data-auto="total_compute_cost"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                    <!-- <div class="align-center d-flex">
                        <span class="mr-1">
                            {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                        </span>
                        <HelpToolTip
                            :size="16">
                            <template #message>
                                <div class="d-flex flex flex-column pa-1">
                                    <div class="d-flex flex flex-row justify-space-between">
                                        <span class="mr-4">
                                            {{ $t('billings.undiscountedComputeCost') }}
                                        </span>
                                        <span>
                                            {{ $t('currency', [historicalBills?.undiscountedComputeCost?.toFixed(2)]) }}
                                        </span>
                                    </div>
                                    <div class="d-flex flex flex-row justify-space-between">
                                        <span class="mr-4">
                                            {{ $t('billings.discount') }}
                                        </span>
                                        <span>
                                            {{ historicalBills?.discount?.toFixed(2) }}%
                                        </span>
                                    </div>
                                    <div class="d-flex flex flex-row justify-space-between">
                                        <span class="mr-4">
                                            {{ $t('billings.discountedComputeCost') }}
                                        </span>
                                        <span>
                                            {{ $t('currency', [historicalBills?.discountedComputeCost?.toFixed(2)]) }}
                                        </span>
                                    </div>
                                    <div class="d-flex flex flex-row justify-space-between">
                                        <span class="mr-4">
                                            {{ $t('billings.minComputeCost') }}
                                        </span>
                                        <span>
                                            {{ $t('currency', [historicalBills?.minComputeCost?.toFixed(2)]) }}
                                        </span>
                                    </div>
                                    <div class="separator"></div>
                                    <div class="d-flex flex flex-row justify-space-between">
                                        <span class="mr-4">
                                            {{ $t('billings.totalComputeCost') }}
                                        </span>
                                        <span>
                                            {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </HelpToolTip>
                    </div> -->
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.storageCost') }}
                <div
                    data-auto="total_storage_cost"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalStorageCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.dataTransferCost') }}
                <div
                    data-auto="data_transfer_cost"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.dataTransferCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <div class="align-center d-flex">
                    <span class="mr-1">
                        {{ $t('billings.supportCost') }}
                    </span>
                    <HelpToolTip
                        :size="16">
                        <template #message>
                            <p>
                                {{ $t('billings.supportType',[historicalBills?.supportType]) }}
                            </p>
                        </template>
                    </HelpToolTip>
                </div>
                <div
                    data-auto="support_cost"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.supportCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <span class="font-weight-bold">{{ $t('total') }}</span>
                <div
                    data-auto="total"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.total?.toFixed(2)]) }}
                </div>
            </v-col>
        </v-row>

        <!-- <v-row
            data-auto="billing-summary"
            class="billing-summary pa-2 mt-6">
            <v-col cols="4">
                {{ $t('billings.minComputeCost') }}
                <div
                    data-auto="min-compute-cost"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.minComputeCost?.toFixed(2)]) }}
                </div>
            </v-col>
            <v-col cols="4">
                {{ $t('billings.totalComputeCost') }}
                <div
                    data-auto="unused-compute-commit"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                </div>
            </v-col>
            <v-col cols="4">
                {{ $t('billings.unusedComputeCommit') }}
                <div
                    data-auto="unused-compute-commit"
                    class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.unusedComputeCommit?.toFixed(2)]) }}
                </div>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
    import HelpToolTip from 'components/common/HelpTooltip'

    const HistoricalBillSummary = {
        name: 'HistoricalBillSummary',
        components: { HelpToolTip, },
        props: {
            historicalBills: {
                required: true,
                type: Object,
            },
        },
    }
    export default HistoricalBillSummary
</script>
