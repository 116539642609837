<style lang="scss" scoped>
.minimized-icon-boxes {
    .type-card {
        position: relative;
        border-radius: 10px 0px 0px 10px;
    }
    .left-card {
        border-radius: 0px 10px 10px 0px;
        margin-left: -2px;
    }
    div.oversize-min-icon-box {
        border: 2px solid #e8eef1;
    }
    .selection-status {
        position: absolute;
            right: 0;
            top: 13px;
    }
    .icon-title {
        font-weight: 500;
        font-size: 1rem;
    }
}
</style>
<template>
    <div
        :class="[
            isPositionRight ? 'right-card' : 'left-card',
            'type-card pa-1',
            'clickable-element',
            (isSelected && 'highlight-instance-row') || 'oversize-min-icon-box'
        ]"
        :data-auto="dataAutoPrefix('card')"
        @click="onClick()">
        <span class="pl-4">
            <v-icon
                :size="iconSize"
                class="mr-3">{{ icon }}</v-icon>
        </span>
        <span
            :data-auto="dataAutoPrefix('title')"
            class="icon-title mr-2">
            {{ title }}
        </span>
        <TechPreview
            v-if="techPreview" />
        <TechPreview
            v-if="freeTier"
            class="ml-1"
            :label="$t('free')" />
        <span class="selection-status">
            <v-icon
                v-if="isSelected"
                size="20">$vuetify.icons.statusOk</v-icon>
        </span>
    </div>
</template>
<script>
    import TechPreview from 'components/common/TechPreview.vue'
    const MinimizedDatabaseTypeCard = {
        name: 'minimized-database-type-card',
        components: {
            TechPreview,
        },
        props: {
            type: String,
            title: String,
            icon: String,
            id: String,
            selectedId: String,
            iconSize: Number,
            parentDataAutoPrefix: Function,
            isPositionRight: Boolean,
            techPreview: Boolean,
            freeTier: Boolean,
        },
        computed: {
            isSelected() {
                return this.id === this.selectedId
            },
        },
        methods: {
            onClick() {
                this.$emit('handleDatabaseRequirementSelection', {id: this.id, type: this.type,})
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(this.type + '-min-' + string)
            },
        },
    }
    export default MinimizedDatabaseTypeCard
</script>
