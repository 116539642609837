<style lang="scss" scoped>
    .v-card--reveal {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .v-card__text {
        height: calc(100% - 44px);
    }
</style>

<template>
    <v-container>
        <v-card :loading="loading">
            <v-card-text>
                <div>{{ $t('billings.savedTaxInformation') }}</div>
                <template v-if="tax?.id">
                    <p class="text-h4 text--primary">
                        {{ tax.registration }}
                    </p>
                    <p class="text--secondary mb-0">
                        {{ tax.country || '' }}
                    </p>
                    <p class="text--secondary">
                        {{ tax.postalCode || '' }}
                    </p>
                </template>
                <template v-else>
                    <p class="pt-4 text--secondary">
                        {{ $t('notFound') }}
                    </p>
                </template>
            </v-card-text>
            <v-card-actions>
                <template v-if="tax?.id">
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading"
                        @click="editTax">
                        {{ $t('edit') }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading"
                        @click="reveal = true">
                        {{ $t('delete') }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading"
                        @click="addNewTax">
                        {{ $t('add') }}
                    </v-btn>
                </template>
            </v-card-actions>

            <v-expand-transition v-if="tax?.id">
                <v-card
                    v-if="reveal"
                    class="transition-fast-in-fast-out v-card--reveal">
                    <v-card-text class="pb-0">
                        <p class="text-h4 text--primary">
                            {{ $t('billings.deleteTaxConfirmationTitle') }}
                        </p>
                        <p>
                            {{ $t('billings.deleteTaxConfirmationMessage') }}
                        </p>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-btn
                            text
                            color="primary"
                            :disabled="loading"
                            @click="reveal = false">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            :disabled="loading"
                            @click="onDeleteTax">
                            {{ $t('yesDelete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-card>

        <TaxModal ref="taxModal" />
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import TaxModal from 'components/billing/taxManagement/TaxModal.vue'

    export default {
        name: 'TaxCard',
        components: {
            TaxModal,
        },
        props: {
            tax: { type: Object, required: true, },
            loading: { type: Boolean, default: false, },
        },
        data () {
            return {
                reveal: false,
            }
        },
        computed: {
            ...mapGetters([
                'deleteTaxSuccess'
            ]),
        },
        methods: {
            ...mapActions([
                'fetchTax',
                'deleteTax'
            ]),
            openTaxModal ({ mode, tax, }) {
                this.$refs.taxModal.open({ mode, tax, })
            },
            editTax() {
                this.openTaxModal({ mode: 'edit', tax: this.tax, })
            },
            async onDeleteTax() {
                await this.deleteTax(this.tax.id)
                this.reveal = false
                if (this.deleteTaxSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.taxDeletedSuccessfully'), type: '#03a128', })
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.deleteTax'), type: this.$config.messageType.ERROR, })
                }
                await this.fetchTax()
            },
            addNewTax () {
                this.openTaxModal({ mode: 'create', })
            },
        },
    }
</script>
