import to from 'await-to-js'
import i18n from 'plugins/i18n'
import { grantToken, refreshToken, logout, impersonate } from 'services/auth'
import { getUserById } from 'services/users'
import { getOrgDetails } from 'services/organizations'
import { getRandomString } from 'helpers'

export default {
    state: {
        authToken: null,
        expiry: null,
        authError: false,
        isFetchingToken: false,
        interval: null,
        authorized: true,
        redirectSSO: false,
        refreshTokenExpiry: null,
        isRefreshingToken: false,
        impersonatedUser: null,
    },
    mutations: {
        setToken(state, payload) {
            state.authToken = payload.access_token
            state.expiry = payload.expires_in
            state.userData = payload.user
            state.refreshTokenExpiry = payload.refresh_expires_in
        },
        setUserAuthorized(state, payload) {
            state.authorized = payload
        },
        setTokenErrors(state, payload) {
            state.authError = payload
        },
        setIsFetchingToken(state, payload) {
            state.isFetchingToken = payload
        },
        setRefreshInterval(state, intervalId) {
            state.interval = intervalId
        },
        setIsRefreshingToken(state, status) {
            state.isRefreshingToken = status
        },
        setSSORedirect(state, flag) {
            state.redirectSSO = flag
        },
        setImpersonation(state, payload) {
            state.impersonatedUser = payload
        },
    },
    actions: {
        async getToken({ commit, dispatch, }, { code, state, options, }) {
            commit('setIsFetchingToken', true)
            const [error, response] = await to(grantToken(code, state, options))
            if (!error) {
                const {
                    data: { user, org, actor, },
                } = response
                commit('setToken', response.data)
                dispatch('setUser', user, { root: true, })
                dispatch('setOrg', org, { root: true, })
                dispatch('setupImpersonation', actor, { root: true, })
                commit('setTokenErrors', false)
            } else {
                commit('setTokenErrors', error)
            }
            commit('setIsFetchingToken', false)
        },
        async getRefreshToken({ commit, dispatch, }) {
            commit('setIsRefreshingToken', true)
            const [error, response] = await to(refreshToken())
            if (response) {
                const {
                    data: { user, org, actor, },
                } = response
                commit('setToken', response.data)
                dispatch('setUser', user, { root: true, })
                dispatch('setOrg', org, { root: true, })
                dispatch('setupImpersonation', actor, { root: true, })
                commit('setTokenErrors', false)
            } else {
                commit('setTokenErrors', error)
                commit('setToken', {})
            }
            commit('setIsRefreshingToken', false)
        },
        refreshAccessTokenRecursively({ state, commit, dispatch, }, seconds) {
            clearInterval(state.interval)
            const intervalId = setInterval(async () => {
                if (state.isRefreshingToken) {
                    return false
                }
                await dispatch('getRefreshToken')
            }, seconds * 1000)
            commit('setRefreshInterval', intervalId)
        },
        stopRefreshingAccessToken({ state, commit, }) {
            clearInterval(state.interval)
            commit('setRefreshInterval', null)
        },
        async logoutUser({ commit, }) {
            commit('setToken', {
                /* eslint-disable camelcase */
                access_token: null,
                expires_in: null,
                user: null,
                /* eslint-enable */
            })
            await to(logout())
            window.location.href = `${process.env.VUE_APP_LOGOUT_URL}?returnTo=${window.location.origin}`
        },
        ssoRedirect({ commit, }, flag) {
            commit('setSSORedirect', flag)
        },
        async setupImpersonation({ commit, }, actor) {
            let impersonatedUser = null
            if (actor) {
                const [error, response] = await to(
                    Promise.all([
                        getOrgDetails(actor.orgId),
                        getUserById(actor.userId)
                    ])
                )
                if (!error) {
                    // In case of impersonation set the
                    // Org Details of the actor user
                    commit('setOrg', response[0])
                    impersonatedUser = response[1]
                }
            }
            // Commit if actor found or not to reflect true state
            commit('setImpersonation', impersonatedUser)
        },
        async impersonateUser({ commit, }, email) {
            const [error, data] = await to(impersonate(email))
            if (error || !data?.data?.acrValues) {
                commit('showMessage', {
                    text: i18n.t('errors.unknown'),
                    type: 'error',
                })
                return false
            } else {
                const params = new URLSearchParams({
                    /* eslint-disable camelcase */
                    response_type: 'code',
                    state: getRandomString(12),
                    client_id: process.env.VUE_APP_AUTH_IMPERSONATION_CLIENT_ID,
                    scope: 'openid profile email',
                    nonce: getRandomString(18),
                    redirect_uri: `${window.location.origin}/login/oauth2/code/impersonation`,
                    /* eslint-enable */
                })
                return window.location.href = `${process.env.VUE_APP_ID_URL}?${params}&acr_values=${data.data.acrValues}`
            }
        },
    },
    getters: {
        authToken: (state) => state.authToken,
        expiry: (state) => state.expiry,
        authError: (state) => state.authError,
        isFetchingToken: (state) => state.isFetchingToken,
        userData: (state) => state.userData,
        redirectSSO: (state) => state.redirectSSO,
        authorized: (state) => state.authorized,
        refreshTokenExpiry: (state) => state.refreshTokenExpiry,
        isRefreshingToken: (state) => state.isRefreshingToken,
        impersonatedUser: (state) => state.impersonatedUser,
    },
}
