export function serviceConnections ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `mariadb_global_status_threads_connected{kubernetes_namespace="${id}"}`
    }
}

export function serviceUsedConnections ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_status_threads_connected{kubernetes_namespace="${id}"}/mariadb_global_variables_max_connections{kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceConnectionsPerServer ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_status_threads_connected{kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceMaxConnectionsPerServer ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_variables_max_connections{kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceAbortedConnections ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_global_status_aborted_connects{kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceAbortedConnectionsPerServer ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_status_aborted_connects{kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceAbortedClients ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_global_status_aborted_clients{kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serverConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_threads_connected{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverAbortedConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(increase(mariadb_global_status_aborted_connects{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverAbortedClients ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(increase(mariadb_global_status_aborted_clients{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverMaxConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `(sum(mariadb_global_variables_max_connections{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) or sum(mariadb_global_variables_max_connections{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"} offset 1m ))- sum(mariadb_global_status_threads_connected{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})`
    }
}

export function msxTotalConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(abs(maxscale_service_connections{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})) by (kubernetes_pod_name)`
    }
}

export function msxConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(abs(maxscale_service_connections{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})) by (kubernetes_pod_name, id)`
    }
}

export function mxsServiceConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(maxscale_service_connections{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (mariadb, id)`
    }
}

export function msxServerConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(sum by (id) (sum without (kubernetes_pod_name) (label_replace(maxscale_server_connections{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}, "kubernetes_pod_name", "$1", "maxscale_pod_name", "(.*)"))) * on (id) group_left (kubernetes_pod_name) sum by (kubernetes_pod_name, id) (label_replace(mariadb_up, "id", "$1", "kubernetes_pod_name", "(.*)"))) by (kubernetes_pod_name)`
    }
}

export function mxsXpandServerConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum by (id) (maxscale_server_connections{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", mariadb_com_topology_type="xpand-direct", id!="Xpand-Bootstrap"})`
    }
}

export function msxServiceLevelServerConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(sum by (id) (sum without (kubernetes_pod_name) (label_replace(maxscale_server_connections{mariadb=~"${name}", kubernetes_namespace="${id}"}, "kubernetes_pod_name", "$1", "maxscale_pod_name", "(.*)"))) * on (id) group_left (kubernetes_pod_name) sum by (kubernetes_pod_name, id) (label_replace(mariadb_up, "id", "$1", "kubernetes_pod_name", "(.*)"))) by (kubernetes_pod_name)`
    }
}
