import base from 'api/base'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
// TODO:: Added placeholder URI, might change when real API comes in
const billingURI = 'billing/v1'

export default {
    get (uri, params, options) {
        return base.get(`${baseUrl}/${billingURI}/${uri}`, params, options)
    },
    post (uri, body, params, options) {
        return base.post(`${baseUrl}/${billingURI}/${uri}`, body, params, options)
    },
    patch (uri, body, params, options) {
        return base.patch(`${baseUrl}/${billingURI}/${uri}`, body, params, options)
    },
    delete (uri, params) {
        return base.delete(`${baseUrl}/${billingURI}/${uri}`, params)
    },
}
