<style lang="scss" scoped>
.topology-container {
    float: left;
    height: auto;
    position: relative;
    border: 2px solid #bed1da;
    border-radius: 0.5rem;
    width: 100%;
    position: relative;
    &:nth-child(odd):not(:only-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &:nth-child(even):not(:only-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    .icon-container {
        min-height: 6rem;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
    .topology-subtitle {
        font-size: 12px;
        color: #a6a4a6;
    }
    .topology-selected-icon {
        position: absolute;
        right: 0px;
        top: 10px;
    }
    .topology-info {
        max-width: 520px;
        margin: 0 auto;
    }
}
.gray-bg {
    background-color: #F9FAFB;
    color: $separator;
    cursor: auto;

    .gray-icon{
        opacity: 0.3;
    }
}
.tech-preview{
    position: absolute;
    top: -20px
}
.serverless-tip {
    border: 1px solid;
}
</style>

<template>
    <v-col
        :cols="colSpan"
        :class="[
            'topology-container pb-6 pointer',
            shouldDisable && 'gray-bg',
            isSelected && 'highlight-instance-row',
        ]"
        @click="onClick"
        @mouseover="showTooltip"
        @mouseleave="hideTooltip">
        <div class="topology-info">
            <TechPreview
                v-if="isTechPreview"
                class="mt-8" />
            <div
                v-if="isSelected"
                class="topology-selected-icon">
                <v-icon
                    size="20"
                    class="mr-4 mt-4">
                    $vuetify.icons.statusOk
                </v-icon>
            </div>
            <div class="text-center icon-container">
                <div class="text-subtitle-1 font-weight-medium topology-server">
                    <div>{{ serverType }}</div>
                    <div>{{ title }}</div>
                </div>
                <v-icon
                    :size="iconSize"
                    :class="{ 'gray-icon': shouldDisable }">
                    {{ icon }}
                </v-icon>
            </div>

            <p class="topology-description">
                {{ description }}
            </p>
            <ul>
                <!-- eslint-disable vue/no-v-html -->
                <li
                    v-for="(feature, index) in features"
                    :key="index"
                    v-html="feature">
                </li>
                <!--eslint-enable-->
            </ul>
            <div
                v-if="isServerless"
                class="serverless-tip info--text mt-4 pa-2 rounded text-caption">
                <p class="ma-0">
                    {{ $t('quickTip') }}: {{ $t('scuHoursTip') }}
                </p>
                <a
                    :href="docLink"
                    target="_blank">
                    {{ $t('learn-more') }}
                </a>
            </div>
            <v-tooltip
                v-if="isToolTipVisible"
                bottom
                max-width="300"
                :nudge-right="100"
                :nudge-bottom="10">
                <template #activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on" />
                </template>
                <span>{{ tooltipText }}</span>
            </v-tooltip>
        </div>
    </v-col>
</template>
<script>
    import i18n from 'plugins/i18n'
    import { CONFIG } from 'config/index.js'
    import TechPreview from 'components/common/TechPreview.vue'
    import { isServerlessTopology } from 'utils/service'

    const TopologyCard = {
        name: 'topology-card',
        components: { TechPreview, },
        props: {
            title: String,
            description: String,
            id: String,
            features: Array,
            icon: String,
            iconSize: Number,
            currentTopologyId: String,
            colSpan: Number,
            serverType: String,
            shouldDisable: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: '',
            },
            isTechPreview: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isToolTipVisible: false,
                tooltipText: '',
            }
        },
        computed: {
            isSelected () {
                return this.currentTopologyId === this.id
            },
            isServerless() {
                return isServerlessTopology(this.type)
            },
            docLink() {
                return CONFIG.SKYSQL_INSTANCE_SIZES_DOCS_URL
            },
        },
        methods: {
            onClick () {
                if(!this.shouldDisable) this.$emit('handleTopologySelection', this.id)
            },
            showTooltip(){
                if(!this.shouldDisable) {
                    return
                }
                this.isToolTipVisible = true
                this.tooltipText = i18n.t(`launchService.unavailableNote.${this.type}`)
            },
            hideTooltip(){
                if(!this.shouldDisable) {
                    return
                }
                this.isToolTipVisible = false
                this.tooltipText = ''
            },
        },
    }

    export default TopologyCard
</script>
