<style lang="scss" scoped>
    .instance-metrics {
        border: 2px solid transparent;
    }
    .even {
        background-color: $even-bg;
    }
    .warning-text{
        color: $warning
    }
    .grayed-text{
        color: $grayed-out;
    }
</style>

<template>
    <div>
        <div
            v-if="instanceDataWithCost.length"
            class="text-body-2">
            <v-row class="grayed-text">
                <v-col cols="1" />
                <v-col
                    class="text-center"
                    cols="2">
                    {{ $t('launchService.instance-size') }}
                </v-col>
                <v-col
                    class="text-center"
                    cols="2">
                    {{ $t('launchService.cpu') }}
                </v-col>
                <v-col
                    class="text-center"
                    cols="1">
                    {{ $t('launchService.memory') }}
                </v-col>
                <v-col
                    class="text-center"
                    cols="3">
                    {{ $t('launchService.cost-per-node') }}
                </v-col>
                <v-col cols="3" />
            </v-row>
            <v-row
                v-for="(instanceItem, index) in instanceDataWithCost"
                :key="instanceItem.size"
                :class="[
                    'instance-metrics pointer',
                    index % 2 === 0 && 'even',
                    currentInstanceId === instanceItem.id &&
                        'highlight-instance-row',
                ]"
                :data-auto="dataAutoPrefix(`content-instance-${index + 1}`)"
                @click="onSelect(instanceItem)">
                <v-col
                    cols="1"
                    class="py-0 pt-3">
                    <v-icon
                        v-if="currentInstanceId === instanceItem.id"
                        size="20"
                        style="color: #7dd012"
                        class="text-right">
                        $vuetify.icons.statusOk
                    </v-icon>
                </v-col>
                <v-col
                    class="text-center"
                    cols="2">
                    {{ $t('sky') }}-<strong>{{ instanceItem.sizeLabel }}</strong>
                </v-col>
                <v-col
                    class="text-center"
                    cols="2">
                    <span class="font-weight-bold pr-1">{{ instanceItem.cpuNum }}</span>{{ $tc('launchService.vcpu') }}
                </v-col>
                <v-col
                    class="text-center"
                    cols="1">
                    <span class="font-weight-bold pr-1">{{ instanceItem.ramNum }}</span>{{ $tc('launchService.gb') }}
                </v-col>
                <v-col
                    class="text-center"
                    cols="3">
                    {{ $t('currency',[$tc('perHour', instanceItem, { hour: parseFloat(instanceItem.pricePerHour).toFixed(5) })]) }}
                </v-col>
                <v-col
                    class="text-center grayed-text"
                    cols="3">
                    {{ $t('currency',[$tc('perMonth', instanceItem, { month: parseFloat(instanceItem.pricePerMonth).toFixed(2) })]) }}
                </v-col>
            </v-row>
            <v-row
                v-if="showServiceLagWarning"
                justify="center">
                <div class="warning-text text-title font-weight-bold mt-2">
                    {{ $t('serviceLagWarning') }}
                </div>
            </v-row>
        </div>
        <div
            v-else
            class="pt-8">
            <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2">
                {{ $t('launchService.no-config-available') }}
            </v-alert>
        </div>
    </div>
</template>

<script>
    const ServerInstanceList =  {
        name : 'ServerInstanceList',
        props: {
            instanceList: {
                type: Array,
                required: true,
            },
            parentDataAutoPrefix: {
                type: Function,
                required: true,
            },
            preSelectedInstanceId: {
                type : String,
                default: '',
            },
            instanceCost: {
                type: Array,
                required: true,
            },
            isAWS: {
                type: Boolean,
                default: false,
            },
        },
        data: function() {
            return {
                currentInstanceId: this.preSelectedInstanceId,
            }
        },
        computed: {
            instanceDataWithCost() {
                return this.filterByLowestPrice(this.instanceList.map((instance) => {
                    const findInstanceCost = this.instanceCost.find(
                        (cost) =>
                            cost.name.toLowerCase() === instance.name &&
                            cost.arch === instance.architecture
                    )

                    if (findInstanceCost) {
                        let {
                            pricePerMonth,
                            pricePerHour,
                            infraPricePerHour,
                            infraPricePerMonth,
                        } = findInstanceCost
                        instance = {
                            ...instance,
                            pricePerMonth,
                            pricePerHour,
                            infraPricePerHour,
                            infraPricePerMonth,
                        }
                    }
                    return instance
                }))
            },
            showServiceLagWarning(){
                /**
                 * The logic to show the warning here is
                 * when a user selects a new instance from the list
                 * this logic checks if the new selection is not lower
                 * configuration than active instance running for the
                 * service. If the selection is equal or greater to active
                 * show no message and break
                 */
                if(!this.preSelectedInstanceId) return false
                let showServiceLag = false
                for(const instance of this.instanceList){
                    if(this.preSelectedInstanceId === instance.id){
                        break
                    }
                    if(instance.id === this.currentInstanceId){
                        showServiceLag = true
                        break
                    }
                }
                return showServiceLag
            },
        },
        methods:{
            onSelect(instance){
                this.currentInstanceId = instance.id
                this.$emit('instanceSelected', instance)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(string)
            },
            filterByLowestPrice(instances) {
                const filteredInstances = {}
                instances.forEach(instance => {
                    const name = instance.name
                    const pricePerMonth = parseFloat(instance.pricePerMonth)

                    if (
                        !filteredInstances[name] ||
                        (
                            filteredInstances[name].architecture === 'amd64' &&
                            instance.architecture === 'arm64' && !isNaN(pricePerMonth)
                        )
                    ) {
                        filteredInstances[name] = instance
                    }
                })
                return Object.values(filteredInstances)
            },
        },
        created() {
            // Handle preselect
            if (this.preSelectedInstanceId) {
                const instance = this.instanceDataWithCost.find(instance => instance.id === this.preSelectedInstanceId)
                this.onSelect(instance)
            }
        },
    }

    export default ServerInstanceList
</script>
